import React, {useState} from 'react';
import {useQuery, gql, useMutation} from "@apollo/client";
import YodelChevron from './yodel_chevron';
import { Formik, Form } from 'formik';
import {sessionTimeoutLogout} from './session_timeout_logout_helper';
import AccountDetailsHeadline from './account_details_headline';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      email
      userVerifiedEmailTokens
      userUnverifiedEmailTokens
      userActiveAdditionalEmails
    }
  }
`;

const REMOVE_ADDITIONAL_EMAIL = gql`
  mutation RemoveAdditionalEmail($additionalEmail: String!) {
    removeAdditionalEmail(additionalEmail: $additionalEmail) {
      message
    }
  }
`;

const RESEND_ADDITIONAL_VERIFICATION_EMAIL = gql`
  mutation ResendAdditionalVerificationEmail($additionalEmail: String!) {
    resendAdditionalVerificationEmail(additionalEmail: $additionalEmail) {
      message
      errors
    }
  }
`;

const ManageEmailAddresses  = ({isMobile}) => {
  const { loading, data: userData, error } = useQuery(GET_CURRENT_USER);
  const [removeAdditionalEmail, { data }] = useMutation(REMOVE_ADDITIONAL_EMAIL);
  const [resendAdditionalVerificationEmail, { data: resendData }] = useMutation(RESEND_ADDITIONAL_VERIFICATION_EMAIL);
  const [infoIsShown, setInfoShow] = useState(false);
  const [isModalShown, setModalShow] = useState(false);
  const [removalError, setError] = useState(null);
  const [chosenEmail, setChosenEmail] = useState(null);

  const modelElm = document.getElementsByClassName("email-delete-modal")[0];

  const handleOnClick = () => {
    setInfoShow(!infoIsShown);
  }

  const openDeleteModal = (email) => {
    setChosenEmail(email);
    setModalShow(!isModalShown);
    window.scrollTo(top);
  }

  const handleSubmit = () => {
    removeAdditionalEmail({
      variables: {
        additionalEmail: chosenEmail,
      }
    })
    .then(data => {
      const response = data?.data?.removeAdditionalEmail;
      if (response.message) {
        window.location.href = `/my-account/manage-email-addresses?updated=true&provenance=email-removed`;
      } else {
        setError(response.errors[0]);
      }
    })
    .catch(error => {
      sessionTimeoutLogout(error.message);
      setError(error.message);

      const code = error.graphQLErrors[0]?.extensions?.code;
      if (code === "RECORD_NOT_FOUND") {
        location.reload();
      }
    });
  }

  const handleResend = (email) => {
    resendAdditionalVerificationEmail({
      variables: {
        additionalEmail: email,
      }
    })
    .then(data => {
      const response = data?.data?.resendAdditionalVerificationEmail;
      if (response.message) {
        window.location.href = `/my-account/manage-email-addresses?updated=true&provenance=email-verification-resent`;
      } else {
        setError(response.errors[0]);
      }
    })
    .catch(error => {
      sessionTimeoutLogout(error.message);
      setError(error.message);
    });
  }

  const renderUserEmails = (userData) => {
    if (userData) {
      let emailsArray = [];
      let verifiedEmails = userData.me.userVerifiedEmailTokens;
      let unverifiedEmails = userData.me.userUnverifiedEmailTokens;

      verifiedEmails.forEach(email => {
        emailsArray.push(
          <div className="section-content yodel-container yodel-preferences-container -narrow -desktop-full-width" key={email}>
            <div className="delivery-preferences-section__title -manage-email">
              <div>
                {email}
              </div>
              <span onClick={() => openDeleteModal(email)} className="preferences__edit-link edit-user-name-link remove-email-link">
                Remove
              </span>
            </div>
            <div className="delivery-preferences-section__cta">
              <span onClick={() => openDeleteModal(email)} className="preferences__edit-link hidden-on-mobile edit-user-name-link remove-email-link">
                Remove
              </span>
            </div>
          </div>
        )
      });

      unverifiedEmails.forEach(email => {
        emailsArray.push(
          <div className="section-content yodel-container yodel-preferences-container -narrow -desktop-full-width" key={email}>
            <div className="delivery-preferences-section__title -manage-email">
              <div className="manage-email-flex-column">
                <div>
                  {email}
                </div>
                <div className="error-line">
                  Email address has not yet been verified.
                </div>
                <span onClick={() => handleResend(email)} className="yodel-outline-button -resend-verification-email">
                  Resend verification email
                </span>
              </div>
              <span onClick={() => openDeleteModal(email)} className="preferences__edit-link edit-user-name-link remove-email-link">
                Remove
              </span>
            </div>
            <div className="delivery-preferences-section__cta -manage-email">
              <span onClick={() => openDeleteModal(email)} className="preferences__edit-link hidden-on-mobile edit-user-name-link remove-email-link">
                Remove
              </span>
            </div>
          </div>
        )
      });

      return emailsArray;
    }
  }

  const EmailDeleteModal = ({email}) => {
    return(
      <div className="email-modal-container">
        <div className="email-delete-modal">
          <h3>
            Are you sure?
          </h3>
          <div className="confirmation-container">
            <p>
              Would you like to remove this email address?
            </p>
            <p>{email}</p>
          </div>
          <div className="buttons-container">
            <button onClick={() => setModalShow(false)}
              className="yodel-outline-button -secondary-style">
              No
            </button>
            <button type="submit" className="yodel-outline-button" onClick={handleSubmit}>
              Yes
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section className="my-account-layout-section manage-additional-emails-section">
      <AccountDetailsHeadline backLink="/my-account/account-details" headline="Email addresses" />
      <div className="section-content yodel-container yodel-preferences-container -narrow -desktop-full-width">
        <div className="delivery-preferences-section__title -manage-email">
          <div className="preferences-content__label -full-width">
            {userData && userData.me.email}
          </div>
          <a href="/my-account/change-email-address" className="preferences__edit-link edit-user-name-link">Edit</a>
        </div>
        <div className="delivery-preferences-section__cta">
          <a href="/my-account/change-email-address" className="preferences__edit-link hidden-on-mobile edit-user-name-link">Edit</a>
        </div>
      </div>
      {userData && renderUserEmails(userData)}
      <div className="section-content yodel-container yodel-preferences-container -narrow -desktop-full-width -column-layout">
        {userData && (userData.me.userActiveAdditionalEmails.length < 3) &&
          <a href="/my-account/add-email-address" className="yodel-manage-email-additional-info-container -link">
            <div className="wider-row">
              Add another email address
            </div>
            <div className="thin-row">
              <YodelChevron/>
            </div>
          </a>
        }
        <div className={`yodel-manage-email-additional-info-container ${(userData && userData.me.userActiveAdditionalEmails.length < 3) ? "-top-border" : ""}`}>
          <div className="wider-row">
            What adding an additional email address means.
          </div>
          <div className="thin-row" onClick={handleOnClick}>
            <img src={'/assets/yodel-assets/cross-clear-input.svg'}
              className={`yodel-plus-icon ${infoIsShown ? "-rotated" : null}`}
              alt="plus-information-icon"/>
          </div>
        </div>
        <div className={`yodel-manage-email__opening-section ${infoIsShown ? "-shown" : "-hidden"}`}>
          <p>
            Adding another email address means we will use it to find parcels linked to that email address and display them on your homepage.
          </p>
          <p>
            You can add a maximum of 3 additional email addresses.
          </p>
          <p>
            You cannot sign in to your Yodel account with any additional email addresses you add.
          </p>
        </div>
      </div>

      {isModalShown && <EmailDeleteModal email={chosenEmail} />}
    </section>
  );
};

export default ManageEmailAddresses;
