import React, {useState, useEffect} from 'react';
import MyAccountLandingPage from './my_account_landing_page';
import {useQuery, gql} from "@apollo/client";

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      firstname
      numberOfAddresses
      userHasNeighboursSet
      userHasSafePlaceSet
      userAddress {
        guid
      }
    }
  }
`;

const isMobileWindow = () => {
  return window.innerWidth < 960;
}

const WelcomeHeadLine = ({isMobile, firstname}) => {
  return (
    <div className={`${isMobile ? "-mobile-margin" : "-negative-margin"} section-content yodel-container -narrow -desktop-full-width`}>
      <div className="my-account-welcome-header">
        <h2>Hello {firstname}</h2>
      </div>
    </div>
  );
}

const MyAccountSections = ({state, numberOfAddresses, userHasNeighboursSet, userSafePlace}) => {
  const { loading, data, error } = useQuery(GET_CURRENT_USER);

  return (
    <>
      <div className="section-content yodel-container -narrow -no-horizontal-padding -desktop-full-width">
        {
          (state === "landing") ? <MyAccountLandingPage /> : null
        }

      </div>
      <div className="section-content yodel-container -narrow -desktop-full-width">
        <a href={numberOfAddresses === 0 ? "/my-details" : "/my-account/delivery-addresses"}
          className="my-account-section-opener">
          <h3>Delivery preferences</h3>
          <img src="/assets/2017/icons/yodel-chevron-green.svg" className="yodel-faq-button__chevron"></img>
        </a>
        {numberOfAddresses === 0 && (
          <div className="my-account-no-address-container">
            <img src="/assets/yodel-assets/blue-info-icon.svg" className="yodel-my-account-info-icon"></img>
            <p className="yodel-my-account-address-text">
              Please set your address and tell us if there is a safe place or neighbour we can deliver to if you are not in.
            </p>
          </div>
        )}
        { (numberOfAddresses !== 0 && (!userHasNeighboursSet || !userSafePlace)) && (
            <div className="my-account-no-address-container">
              <img src="/assets/yodel-assets/blue-info-icon.svg" className="yodel-my-account-info-icon"></img>
              <p className="yodel-my-account-address-text">
                Tell us if there is a safe place or neighbour we can deliver to if you are not in.
              </p>
            </div>
          )
        }
      </div>
      <div className="section-content yodel-container -narrow -desktop-full-width">
        <a href="/my-account/account-details" className="my-account-section-opener">
          <h3>Account details</h3>
          <img src="/assets/2017/icons/yodel-chevron-green.svg" className="yodel-faq-button__chevron"></img>
        </a>
      </div>
      <div className="section-content yodel-container -narrow -desktop-full-width">
        <a href="/help-centre" className="my-account-section-opener">
          <h3>Help</h3>
          <img src="/assets/2017/icons/yodel-chevron-green.svg" className="yodel-faq-button__chevron"></img>
        </a>
      </div>
      <div className="section-content yodel-container -narrow -desktop-full-width">
        <a href="/logout" className="my-account-section-opener">
          <h3>Log out</h3>
        </a>
      </div>
    </>
  );
}

const MyAccountLayout = ({children, state})  => {
  const { loading, data, error } = useQuery(GET_CURRENT_USER);

  const [isMobile, setIsMobile] = useState(isMobileWindow());

  const handleResize = () => {
    setIsMobile(isMobileWindow());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <section className={`${isMobile ? "-mobile" : "hidden-on-mobile"} my-account-layout-section`}>
      <WelcomeHeadLine firstname={data && data.me.firstname} isMobile={isMobile} />
      <MyAccountSections state={state} numberOfAddresses={data && data.me.numberOfAddresses}
        userHasNeighboursSet={data && data.me.userHasNeighboursSet} userSafePlace={data && data.me.userHasSafePlaceSet} />
    </section>
  );
};

export default MyAccountLayout;
