import React, {useState, useEffect} from 'react';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import TextField from './text_field';
import ErrorBanner from '../my_account/error_banner';

const validate = (values) => {
  const errors = {};

  const emailRegex =
    /^(?!.*\.@|.*\.{2})[a-zA-Z0-9-]+[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]*@[a-zA-Z0-9-]+\.+([a-zA-Z0-9-]{2,})+(\.+([a-zA-Z0-9-]{2,})+)*$/;

  if (!values.email) {
    errors.email = 'Please enter an email address';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Please enter a valid email address';
  }

  return errors;
}

const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail($email: String!) {
    resendVerificationEmail(email: $email) {
      message
    }
  }
`;

const SuccessBanner = ({success}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false)
    }, 5000)

    return () => {
      clearTimeout(timeId)
    }
  }, []);

  if (!show) {
    return null;
  } else {
    return (
      <div className="react-flash-message -verification-sent">
        {success}
      </div>
    )
  }
}

const VerificationLinkInvalid  = ({isMobile}) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [resendVerificationEmail, { data }] =
    useMutation(RESEND_VERIFICATION_EMAIL);

  const handleSubmit = (values, actions) => {
    resendVerificationEmail(
      {variables: {email: values.email}}
    )
    .then(data => {
      const response = data.data && data.data.resendVerificationEmail;

      actions.setSubmitting(false);
      setSuccess(response.message);
    })
    .catch(error => {
      actions.setSubmitting(false);
      setError(error.message);
      scrollToErrorBanner();
    });
  };

  const scrollToErrorBanner = () => {
    document.getElementsByClassName("yodel-registration-error-banner")[0]
      .scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <div className={(isMobile ? "" : "-with-border ") + "signup-content email-link-invalid-container"}>
      {error && <ErrorBanner error={error}/>}
      {success && <SuccessBanner success={success}/>}

      <div className="expiry-line">
        <img src="/assets/yodel-assets/timer-icon-red.svg" alt="timer-icon" />
        <p className="blue-paragraph">Verification link invalid</p>
      </div>

      <ul className="email-verification-list -third-list-item-empty">
        <li>Sorry, the link to verify your email address is now invalid. </li>
        <li>
          Please check if we have sent you a more up-to-date verification email or request a new one.
        </li>
        <li>
        </li>
      </ul>

      <p>Resend verification email</p>

      <Formik
        initialValues={{
          email: '',
        }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, errors, isValid }) => (
          <Form
            className="resend-verification-form"
            noValidate
          >
            <TextField
              label="Email address"
              name="email"
              type="email"
              showValidMarker={false}
            />

            <input
              type="submit"
              name="submit"
              value="Send"
              className="yodel-outline-button resend-verification-link"
              disabled={isSubmitting || !values?.email}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VerificationLinkInvalid;
