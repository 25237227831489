import React from 'react';
import ReactDOM from 'react-dom';
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  ApolloProvider,
  concat
} from "@apollo/client";

import MyAccount from '../my_account/my_account';
const mountNode = document.getElementById('my-account');

const csrfToken = document.querySelector('meta[name="csrf-token"]') &&
  document.querySelector('meta[name="csrf-token"]').getAttribute("content");
const httpLink = new HttpLink({ uri: '/graphql' });
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-CSRF-Token': csrfToken,
    }
  }));

  return forward(operation);
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  }
};
const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <MyAccount
      config={{
        errorMessage: mountNode.dataset.errorMessage,
        userId: mountNode.dataset.userId,
        userEmail: mountNode.dataset.userEmail
      }}
    />
  </ApolloProvider>,
  mountNode
);
