import React, { useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import TextField from "./text_field";
import PasswordField from "./password_field";
import Checkbox from "./checkbox";
import axios from "axios";
import { validate } from "./validation";
import ReCAPTCHA from "react-google-recaptcha";

const RegistrationForm = ({onError, resetError, onSuccess}) => {
  const [recaptchaEnabled, setRecaptchaEnabled] = useState(false);
  const [recaptchaSiteKey, setRecaptchaSiteKey] = useState();

  const recaptchaRef = useRef();

  useEffect(() => {
    const appElement = document.querySelector(".app");
    setRecaptchaEnabled(appElement.dataset.recaptchaEnabled === "true");
    if (recaptchaEnabled) {
      setRecaptchaSiteKey(appElement.dataset.recaptchaSiteKey);
    }
  });

  const handleSubmit = async (values, actions) => {
    resetError();

    const body = {
      user: {
        firstname: values.firstname,
        surname: values.surname,
        email: values.email,
        password: values.password,
        marketing_opt_in: values.marketing_opt_in,
        yodel_terms_of_service: values.yodel_terms_of_service,
        partners_marketing_opt_in: values.partners_marketing_opt_in,
      },
      responseType: "json",
    };

    if (recaptchaEnabled) {
      const token = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();

      body["recaptcha_token"] = token;
    }

    axios.post("/api/v2/user.json", body)
    .then(response => onSuccess(response.data.email, response.data.id))
    .catch(error => {
      actions.setSubmitting(false);
      handleSubmitError(error.response);
      scrollToErrorBanner();
    })
  };

  const handleSubmitError = (error) => {
    let errorMessage = "";
    if (error.status === 422 ) {
      errorMessage = Object.values(error.data.error)[0][0];
    } else {
      errorMessage = error.data.error;
    }
    onError(errorMessage);
  };

  const scrollToErrors = (errors) => {
      const errorKeys = Object.keys(errors);
      if (errorKeys.length > 0) {
        document.getElementsByName(errorKeys[0])[0]
          .scrollIntoView({ behavior: "smooth", block: "center" });
      }
   };

   const scrollToErrorBanner = () => {
     document.getElementsByClassName("yodel-registration-error-banner")[0]
       .scrollIntoView({ behavior: "smooth", block: "center" });
   };

   const Effect = (props) => {
     const effect = () => {
       if (!props.isSubmitting && !props.isValid) {
         props.onSubmissionError();
       }
     };
     React.useEffect(effect, [props.isSubmitting]);
     return null;
   };

  return (
    <Formik
      initialValues={{
        firstname: "",
        surname: "",
        email: "",
        password: "",
        marketing_opt_in: false,
        yodel_terms_of_service: false,
        partners_marketing_opt_in: false,
      }}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, isValid }) => (
        <Form className="new_user" noValidate>
          <Effect
            isSubmitting={isSubmitting}
            isValid={isValid}
            onSubmissionError={() => scrollToErrors(errors) }
          />
          <TextField label="First name" name="firstname" type="text" capitaliseFirstLetter="true" />
          <TextField label="Last name" name="surname" type="text" capitaliseFirstLetter="true" />
          <TextField label="Email address" name="email" type="email" />
          <PasswordField label="Password" name="password" simpleValidation />
          <Checkbox name="marketing_opt_in" id="marketing_opt_in" >
            I would like to receive newsletters, updates and information about Yodel via email.
          </Checkbox>
          <Checkbox name="partners_marketing_opt_in" id="partners_marketing_opt_in" >
            I would like to receive updates & special offers from Yodel's partners via email.
          </Checkbox>
          <Checkbox name="yodel_terms_of_service" id="yodel_terms_of_service" >
            I accept the <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
            terms and conditions</a> and <a href="/privacy-cookies-policy" target="_blank" rel="noopener noreferrer">
            privacy statement</a> for this account.
          </Checkbox>
          {
            recaptchaEnabled && recaptchaSiteKey && (
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={recaptchaSiteKey}
              />
            )
          }
          <input
            type="submit"
            name="submit"
            value="Sign up"
            className="yodel-outline-button register-signup-btn"
            disabled={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
}

export default RegistrationForm;
