import React, {useState, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import TwoTabsLayout from './two_tabs_layout';
import SimpleLayout from './simple_layout';
import RegistrationSection from './registration_section';
import LoginPage from './login_page';
import EmailVerificationPage from './email_verification_page';
import AccountSetupLayout from './account_setup_layout';
import MyDetailsPage from './my_details_page';
import MyDetailsNeighbourPage from './my_details_neighbour_page';
import MyAccountLayout from './my_account_layout';
import MyDetailsSafePlacePage from './my_details_safe_place_page';
import ResetPasswordPage from './reset_password_page';
import ResetPasswordVerification from './reset_password_verification';
import ResetPasswordConfirmation from "./reset_password_confirmation";
import ChangePassword from "./change_password";
import ResetPasswordExpired from "./reset_password_expired";
import ActivationEmailExpired from "./activation_email_expired";
import MobileVerificationPage from "./mobile_verification_page";
import VerificationLinkInvalid from "./verification_link_invalid";
import PropertyNotes from "./property_notes";
import ManageEmailAddresses from "./manage_email_addresses";
import ChangeEmailAddress from "./change_email_address";
import EmailChangeVerificationPage from "./email_change_verification_page";
import AddEmailAddressPage from "./add_email_address_page";
import AddUserPasswordPage from "./add_user_password_page";
import AddAdditionalMobileNumber from "./add_additional_mobile_number";
import EmailChangeExpired from "./email_change_expired"
import ManageMobileNumbers from "./manage_mobile_numbers";
import ChangeMobileNumber from "./change_mobile_number";

const MyAccount = ({config}) => {
  return(
    <Router>
      <Switch>
        <Route path="/user/new">
          <TwoTabsLayout
            leftTab={<LoginPage errorMessage={config.errorMessage} />}
            rightTab={<RegistrationSection />}
            activeTab="signup"
          />
        </Route>
        <Route path="/login">
          <TwoTabsLayout
            leftTab={<LoginPage errorMessage={config.errorMessage} />}
            rightTab={<RegistrationSection />}
            activeTab="login"
          />
        </Route>
        <Route path="/users/:id/activation">
          <TwoTabsLayout
            leftTab ={
              <EmailVerificationPage
                userId={config.userId}
                userEmail={config.userEmail}
                provenance="login"
              />
            }
            rightTab={<RegistrationSection />}
            activeTab="login"
          />
        </Route>
        <Route exact path="/my-details">
          <AccountSetupLayout state="my-details" >
            <MyDetailsPage />
          </AccountSetupLayout>
        </Route>
        <Route exact path="/my-details/:guid/edit">
          <AccountSetupLayout state="my-details" >
            <MyDetailsPage />
          </AccountSetupLayout>
        </Route>
        <Route path="/addresses/:guid/neighbours">
          <AccountSetupLayout state="neighbour" >
            <MyDetailsNeighbourPage />
          </AccountSetupLayout>
        </Route>
        <Route path="/addresses/:guid/safe-place">
          <AccountSetupLayout state="safePlace" >
            <MyDetailsSafePlacePage />
          </AccountSetupLayout>
        </Route>
        <Route exact path="/my-details/mobile-verification">
          <AccountSetupLayout state="my-details" >
            <MobileVerificationPage />
          </AccountSetupLayout>
        </Route>
        <Route exact path="/my-account">
          <MyAccountLayout state="landing">
          </MyAccountLayout>
        </Route>
        <Route exact path="/my-account/my-parcels">
          <MyAccountLayout state="landing">
          </MyAccountLayout>
        </Route>
        <Route exact path="/my-account/account-details">
          <MyAccountLayout state="account-settings">
          </MyAccountLayout>
        </Route>
        <Route path="/reset_password/new">
          <TwoTabsLayout
            leftTab ={
              <ResetPasswordPage />
            }
            rightTab={<RegistrationSection />}
            activeTab="login"
          />
        </Route>
        <Route path="/reset_password/yours">
          <TwoTabsLayout
            leftTab ={
              <ResetPasswordVerification />
            }
            rightTab={<RegistrationSection />}
            activeTab="login"
          />
        </Route>
        <Route path="/my-details/reset_password">
          <SimpleLayout>
            <ResetPasswordConfirmation
              csrfToken={config.csrfToken}
            />
          </ SimpleLayout>
        </Route>
        <Route path="/my-account/change-password">
          <SimpleLayout>
            <ChangePassword />
          </ SimpleLayout>
        </Route>
        <Route path="/my-details/reset-password-expired">
          <TwoTabsLayout
            leftTab ={
              <ResetPasswordExpired />
            }
            rightTab={<RegistrationSection />}
            activeTab="login"
          />
        </Route>
        <Route path="/my-account/activation-email-expired">
          <TwoTabsLayout
            leftTab ={
              <ActivationEmailExpired
                userId={config.userId}
              />
            }
            rightTab={<RegistrationSection />}
            activeTab="login"
          />
        </Route>
        <Route path="/my-account/verification-link-invalid">
          <TwoTabsLayout
            leftTab ={
              <VerificationLinkInvalid
                userId={config.userId}
              />
            }
            rightTab={<RegistrationSection />}
            activeTab="login"
          />
        </Route>
        <Route path="/my-account/:guid/property-notes">
          <SimpleLayout>
            <PropertyNotes />
          </ SimpleLayout>
        </Route>
        <Route path="/my-account/manage-email-addresses">
          <ManageEmailAddresses />
        </Route>
        <Route path="/my-account/change-email-address">
          <SimpleLayout>
            <ChangeEmailAddress />
          </ SimpleLayout>
        </Route>
        <Route path="/my-account/verify-email-change">
          <SimpleLayout>
            <EmailChangeVerificationPage />
          </ SimpleLayout>
        </Route>
        <Route path="/my-account/add-email-address">
          <SimpleLayout>
            <AddEmailAddressPage />
          </ SimpleLayout>
        </Route>
        <Route path="/my-account/add-user-password">
          <SimpleLayout>
            <AddUserPasswordPage />
          </ SimpleLayout>
        </Route>
        <Route path="/my-account/add-additional-mobile-number">
          <SimpleLayout>
            <AddAdditionalMobileNumber />
          </ SimpleLayout>
        </Route>
        <Route path="/my-account/email-change-request-expired">
          <SimpleLayout>
            <EmailChangeExpired />
          </ SimpleLayout>
        </Route>
        <Route path="/my-account/manage-mobile-numbers">
          <ManageMobileNumbers />
        </Route>
        <Route path="/my-account/change-mobile-number">
          <SimpleLayout>
            <ChangeMobileNumber />
          </ SimpleLayout>
        </Route>
      </Switch>
    </Router>
  )
};

export default MyAccount;
