import React, {useState, useEffect} from 'react';
import RegistrationPage from '../my_account/registration_page';
import EmailVerificationPage from '../my_account/email_verification_page';

const RegistrationSection = ({isMobile}) => {
  const [error, setError] = useState(null);
  const [pageState, setPageState] = useState('init');
  const [email, setEmail] = useState('init');
  const [id, setUserId] = useState('init');

  const handleSuccess = (email, id) => {
    setPageState('verification');
    setEmail(email);
    setUserId(id);
  };

  return (
    pageState === 'init' ? (
      <RegistrationPage
        isMobile={isMobile}
        onSuccess={(email, id) => handleSuccess(email, id)}
      />
    ) : (
      <EmailVerificationPage
        isMobile={isMobile}
        userEmail={email}
        userId={id}
      />
    )
  )
};

export default RegistrationSection;
