import React, {useState, useEffect} from 'react';
import TextField from './text_field';

const PasswordVerificationSection = () => {
  return (
    <>
      <p className="blue-paragraph">Email sent</p>

      <div className="bordered-container">
        <p>If we’ve got an account registered to this email address, we’ll send a link to reset your password. </p>
      </div>

      <div className="expiry-line">
        <img src="/assets/yodel-assets/timer-icon.svg" alt="timer-icon" />
        <p>The link in the email will expire after 30 minutes.</p>
      </div>
    </>
  );
}

const NotReceivedEmailSection = () => {
  return (
    <>
      <p className="blue-paragraph">Still not received it?</p>

      <ul className="email-verification-list -forgotten-password">
        <li>Make sure to check your spam/junk folder in case it’s there.  </li>
        <li>
          <a href="/reset_password/new">Resend link</a>
        </li>
      </ul>
    </>
  );
}

const ResetPasswordVerification  = ({isMobile, provenance}) => {
  return (
    <div className={(isMobile ? "" : "-with-border ") + "signup-content"}>
      <PasswordVerificationSection />
      <NotReceivedEmailSection />
    </div>
  );
};

export default ResetPasswordVerification;
