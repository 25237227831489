import React from 'react';
import { useField } from 'formik';

const Checkbox = ({ children, ...props}) => {
  const [field, meta] = useField(props);
  return (
    <div className="yodel-checkbox-container">
      <input type="checkbox" {...field} {...props} tabIndex="0" />
      <label htmlFor={field.name}>
        {children}
        <span className="yodel-checkbox-icon"></span>
      </label>

      {meta.touched && meta.error ? (
        <p className="validation_error">{meta.error}</p>
      ) : null}
    </div>
  );
}

export default Checkbox;
