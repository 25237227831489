import React, {useState, useEffect} from 'react';

const ResetPasswordExpired  = ({isMobile}) => {
  return (
    <div className={(isMobile ? "" : "-with-border ") + "signup-content"}>
      <div className="expiry-line">
        <img src="/assets/yodel-assets/timer-icon-red.svg" alt="timer-icon" />
        <p className="blue-paragraph">Reset password link expired</p>
      </div>

      <ul className="email-verification-list -third-list-item-empty">
        <li>Sorry, the link to reset your password has expired. </li>
        <li>The link is only valid for 30 minutes. You will need to request it again to reset your password. </li>
        <li>
          <a href="/reset_password/new">Resend link</a>
        </li>
      </ul>
    </div>
  );
};

export default ResetPasswordExpired;
