import React, {useState, useEffect} from 'react';
import {useQuery, useLazyQuery, useMutation, gql} from "@apollo/client";
import {useParams} from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import YodelDropdown from './yodel_dropdown';
import ErrorBanner from '../my_account/error_banner';
import YodelChevron from '../my_account/yodel_chevron';
import {sessionTimeoutLogout} from './session_timeout_logout_helper';
import {simpleTextRegex} from './validation'

const GET_USER_ADDRESS = gql`
  query GetUserAddress($guid: String!) {
    userAddress(guid: $guid) {
      leaveSafe
      userSafePlace
    }
  }
`;

const CREATE_SAFE_PLACE = gql`
  mutation CreateSafePlace($guid: String!,
    $safePlace: String, $safePlaceOther: String
  ) {
    createSafePlace(userAddressGuid: $guid,
      safePlace: $safePlace, safePlaceOther: $safePlaceOther
    ) {
      userAddressGuid
      errors
    }
  }
`;

const MyDetailsSafePlacePage = ({isMobile}) => {
  const { guid } = useParams();
  const [createSafePlace] = useMutation(CREATE_SAFE_PLACE);
  const { loading, data, error: addressError } =
    useQuery(GET_USER_ADDRESS, {variables: {guid: guid}});
  const [safePlaceError, setSafePlaceError] = useState(null);

  const safePlaceLocationOptions = [
    "Back porch", "Behind bin", "Behind gate", "Doorstep", "Front porch", "Garage", 
    "Outbuilding", "Shed", "Storage box", "Other"
  ];

  const editMode = document.querySelector("[data-edit-mode]").dataset.editMode;
  const provenance = document.querySelector("[data-provenance]").dataset.provenance;

  const isSafePlaceOther = (values) => values.safePlace === "Other";
  
  const shouldShowSafePlaceCopy = (values) => 
    ["Doorstep", "Behind bin", "Behind gate"].includes(values.safePlace);

  const characterCount = (values) => values.safePlaceOther?.length || 0;

  // setTimeOut needed because of https://stackoverflow.com/a/61070604/942986
  const validateSafePlaceOther = (values) => {
    const value = values.safePlaceOther;
    if (!simpleTextRegex.test(value)) {
      setTimeout(() => setSafePlaceError("Describe your safe place using only valid characters including letters, numbers, spaces and - , . * #, excluding line breaks"), 0);
      return false;
    } else {
      setTimeout(() => setSafePlaceError(null), 0);
      return true;
    }
  }

  const isSafePlaceValid = (values) => {
    return (
        isSafePlaceOther(values) &&
        characterCount(values) > 0 &&
        validateSafePlaceOther(values)
      ) ||
      (values.safePlace && !isSafePlaceOther(values));
  }


  const handleSubmit = (values, actions) => {
    createSafePlace(
      {variables: {
        guid: guid,
        safePlace: values.safePlace,
        safePlaceOther: values.safePlaceOther
      }}
    )
    .then(data => {
      const response = data.data && data.data.createSafePlace;
      if (response.userAddressGuid) {
        if (editMode) {
          switch (provenance) {
            case "review" :
              window.location.href = `/my-details/${guid}/review?updated=true&provenance=safe-place`
              break;
            case "preferences" :
              window.location.href = `/my-account/delivery/${guid}?updated=true&provenance=safe-place`
              break;
            case "delivery-preferences" :
              window.location.href = `/my-details/${guid}/review?updated=true`
              break;
            case "additional_delivery_address" :
              window.location.href = `/my-details/${guid}/review?updated=true&provenance=additional_delivery_address`
              break;
          }
        } else {
          window.location.href = `/my-details/${guid}/review`;
        }
      } else {
        actions.setSubmitting(false);
        setSafePlaceError(response.errors[0]);
      }
    })
    .catch(error => {
      actions.setSubmitting(false);
      sessionTimeoutLogout(error.message);
      setSafePlaceError(error.message);
    });
  };

  const clearOtherSafePlace = (setFieldValue) => {
    setFieldValue("safePlaceOther", "");
  }

  const safePlace = () => {
    if (data?.userAddress?.userSafePlace) {
      return "Other";
    } else {
      return data?.userAddress?.leaveSafe;
    }
  }

  const safePlaceOther = () => {
    if (data?.userAddress?.userSafePlace) {
      return data?.userAddress?.leaveSafe;
    } else {
      return '';
    }
  }

  return (
    <Formik
    initialValues={{
      safePlace: safePlace(),
      safePlaceOther: safePlaceOther()
    }}
    enableReinitialize={true}
    onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, errors, isValid, setFieldValue }) => (
        <Form className="new_user my-details-safeplace-form" noValidate>
          {!editMode && 
            <h2>Safe place</h2>
          }
          <p>
            For all you future deliveries, tell us if there is somewhere we can leave your parcels if no-one is in.
          </p>

          {safePlaceError && <ErrorBanner error={safePlaceError} />}

          <p>The safe place for my deliveries is:</p>

          <div className="yodel-form-element-container">
            <YodelDropdown
              aria-label="Safe places"
              options={safePlaceLocationOptions}
              values={values}
              id="my-details-safe-place"
              name="safePlace"
              unselectedLabel="Please select"
              isMobile={isMobile}
              clearInput={true}
              onClearInput={() => clearOtherSafePlace(setFieldValue)}
              onChange={(option) => option !== 'Other' && clearOtherSafePlace(setFieldValue)}
            />
          </div>
          {
            shouldShowSafePlaceCopy(values) || isSafePlaceOther(values) ? (
              <>
                <p className="-big-margin-bottom">
                  We recommend your safe place is covered and out of sight of passing traffic.
                </p>

                {isSafePlaceOther(values) && (
                    <>
                      <Field
                      name="safePlaceOther"
                      rows="3"
                      placeholder="Please type..."
                      as="textarea"
                      className="safe-place-instructions-input"
                      maxLength="50"
                      />

                      <span className="safe-place-character-count">
                      {characterCount(values)}/50 characters
                      </span>
                    </> 
                  )
                }
              </>
            ) : null
          }

          {editMode ?
            <button type="submit" name="submit"
              disabled={isSubmitting || loading || (values.safePlace && !isSafePlaceValid(values))}
              className="yodel-faq-button register-signup-btn -end-of-block-bottom-margin">
              Save
            </button>
          :
            <button
              type="submit"
              name="submit"
              className={`${isSafePlaceValid(values) ? "" : "-secondary-style"} yodel-faq-button register-signup-btn -with-chevron -centered-chevron -end-of-block-bottom-margin`}
              disabled={isSubmitting || loading}>
              {isSafePlaceValid(values) ? "Next" : "Skip"}
              <YodelChevron className="yodel-faq-button__chevron"></YodelChevron>
            </button>
          }
        </Form>
      )}
    </Formik>
  )
};

export default MyDetailsSafePlacePage;
