import React, {useState, useEffect} from 'react';
import { Formik, Form } from 'formik';
import { gql, useMutation, useQuery } from '@apollo/client';
import ErrorBanner from '../my_account/error_banner';

const RESEND_EMAIL_CHANGE_EMAIL = gql`
  mutation ResendEmailChangeEmail($token: String!) {
    resendEmailChangeEmail(token: $token) {
      message
      errors
    }
  }
`;

const EmailChangeLinkExpired  = ({isMobile, userId}) => {
  const [resendEmailChangeEmail, { data }] = useMutation(RESEND_EMAIL_CHANGE_EMAIL);
  const [error, setError] = useState(null);

  const token = window.location.pathname.split("/my-account/email-change-request-expired/")[1];

  const handleSubmit = (values, actions) => {
   resendEmailChangeEmail(
     {variables: {token: token}}
   )
   .then(data => {
     const response = data.data && data.data.resendEmailChangeEmail;

     if (response.message) {
       window.location.href = `/login?updated=true&provenance=email-change-request-resent`;
     } else {
       actions.setSubmitting(false);
       setError(response.errors[0]);
     }
   })
   .catch(error => {
     actions.setSubmitting(false);
     setError(error.message);
   });
  };

  return (
    <div className="section-content yodel-container -narrow -desktop-full-width -negative-margin ">
      {error && <ErrorBanner error={error}/>}

      <div className="expiry-line">
        <img src="/assets/yodel-assets/timer-icon-red.svg" alt="timer-icon" />
        <p className="blue-paragraph">Verification link expired</p>
      </div>

      <ul className="email-verification-list -third-list-item-empty">
        <li>Sorry, the link to verify your email address has expired. </li>
        <li>
          The link is only valid for 30 minutes. You can request a new email with a verification link.
        </li>
        <li>
          <Formik
            initialValues={{}}
            onSubmit={handleSubmit}
          >
            {({ errors, isValid }) => (
              <Form
                className="resend-verification-form"
                noValidate
              >
                <input
                  type="submit"
                  name="submit"
                  value="Resend verification email"
                  className="resend-verification-link"
                />
              </Form>
            )}
          </Formik>
        </li>
      </ul>
    </div>
  );
};

export default EmailChangeLinkExpired;
