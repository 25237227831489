import React, {useState, useEffect} from 'react';
import { Formik, Form } from 'formik';
import { gql, useMutation, useQuery } from '@apollo/client';
import ErrorBanner from '../my_account/error_banner';
import TextField from './text_field';
import {sessionTimeoutLogout} from './session_timeout_logout_helper';
import AccountDetailsHeadline from './account_details_headline';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      id
    }
  }
`;

const ADD_ADDITIONAL_EMAIL = gql`
  mutation AddAdditionalEmail($additionalEmail: String!) {
    addAdditionalEmail(additionalEmail: $additionalEmail) {
      message
    }
  }
`;

const AddEmailAddressPage  = ({isMobile}) => {
  const { loading, data: userData } = useQuery(GET_CURRENT_USER);
  const [addAdditionalEmail, { data }] = useMutation(ADD_ADDITIONAL_EMAIL);
  const [error, setError] = useState(null);

  const handleSubmit = (values, actions) => {
    addAdditionalEmail(
      {variables: {
        additionalEmail: values.email,
      }}
    )
    .then(data => {
      const response = data.data?.addAdditionalEmail;
      if (response.message) {
        window.location.href = `/my-account/account-details?updated=true&provenance=email-added`;
      } else {
        actions.setSubmitting(false);
        setError(response.errors[0]);
      }
    })
    .catch(error => {
      actions.setSubmitting(false);
      sessionTimeoutLogout(error.message);
      setError(error.message);
    });
  };

  const validateEmail = (values) => {
    const emailRegex = /^(?!.*\.@|.*\.{2})[a-zA-Z0-9-]+[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]*@[a-zA-Z0-9-]+\.+([a-zA-Z0-9-]{2,})+(\.+([a-zA-Z0-9-]{2,})+)*$/;

    const emailErrors = {};

    if (!values.email) {
      emailErrors.email = 'Please enter an email address';
    } else if (!emailRegex.test(values.email)) {
      emailErrors.email = 'Please enter a valid email address';
    }

    return emailErrors;
  }

  return (
    <>
      <div className="section-content yodel-reset-password-container -narrow -flex-column">
        <AccountDetailsHeadline backLink="/my-account/manage-email-addresses" headline="Add an email address" />

        <div className="yodel-reset-password-content">
          <p className="blue-paragraph -bold">Just so you know...</p>
          <div className="bordered-container">
            <p>
              Adding an additional email address means we’ll send a verification link to that  email address.
            </p>
          </div>
          {error && <ErrorBanner error={error}/>}
          <Formik
            initialValues={{
              email: '',
            }}
            validate={validateEmail}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting, emailErrors }) => (
              <Form className="reset-password-form" noValidate>
                <TextField
                  aria-label="New email address label"
                  label="New email address" name="email"
                  type="email"
                  showValidMarker={false} />
                <input
                  type="submit"
                  name="submit"
                  value="Save"
                  className="yodel-outline-button reset-password-btn"
                  disabled={isSubmitting}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddEmailAddressPage;
