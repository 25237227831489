import React, {useState} from 'react';
import {useQuery, useLazyQuery, useMutation, gql} from "@apollo/client";
import { Formik, Form, Field } from 'formik';
import TextField from './text_field';
import YodelDropdown from './yodel_dropdown';
import ErrorBanner from '../my_account/error_banner';
import YodelChevron from '../my_account/yodel_chevron';
import {sessionTimeoutLogout} from './session_timeout_logout_helper';
import checkURLParams from "./check_url_params";
import {validateMobile, validate} from './validation';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      mobile
      unverifiedMobile
      numberOfAddresses
    }
  }
`;

const GET_ADDRESSES = gql`
  query GetAddresses($postcode: String!) {
    addresses(postcode: $postcode) {
      uprn
      postcode
      addressLine1
      addressLine2
      town
      county
      latitude
      longitude
    }
  }
`;

const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($address: AddressAttributes, $addressGuid: String!) {
    updateAddress(addressAttributes: $address, addressGuid: $addressGuid) {
      userAddress {
        guid
      }
    }
  }
`;

const UPDATE_USER_MOBILE = gql`
  mutation UpdateMobileNumber($mobile: String!, $password: String) {
    updateMobileNumber(mobile: $mobile, password: $password) {
      message
      unverifiedMobile
    }
  }
`;

const ADD_USER_ADDRESS = gql`
  mutation AddUserAddress($address: AddressAttributes!) {
    addUserAddress(addressAttributes: $address) {
     userAddress {
       guid
     }
    }
  }
`;

const GET_USER_ADDRESS = gql`
  query GetUserAddress($guid: String!) {
    userAddress(guid: $guid) {
      guid
      uprn
      postcode
      addressLine1
      addressLine2
      town
      county
      latitude
      longitude
      leaveSafe
      propertyNotes
      manualAddress
      addresses {
        uprn
        postcode
        addressLine1
        addressLine2
        town
        county
        latitude
        longitude
      }
    }
  }
`;

const MyDetailsPage = ({isMobile}) => {
  const [addressError, setAddressError] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [postcodeAttempted, setPostcodeAttempted] = useState(false);
  const [findAddressThrottleError, setFindAddressThrottleError] = useState(false);
  const [mobileTokenThrottleError, setMobileTokenThrottleError] = useState(false);
  const [addressNotSelectedError, setAddressNotSelectedError] = useState(false);
  const [currentGuid, setCurrentGuid] = useState(null);
  const [editMode, setEditMode] = useState(document.querySelector("[data-edit-mode]").dataset.editMode);
  const [isUserMobileSet, setMobileInput] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [disableSubmission, setDisableSubmission] = useState(false);
  const isDeliveryPrefsTheReferrer = checkURLParams("edit");
  const provenance = document.querySelector("[data-provenance]").dataset.provenance;
  const [isManualEntryEnabled, setManualEntry] = useState(false);
  const [showManualEntryInputs, setManualEntryInputs] = useState(false);
  const [notListedCanProceed, setNotListedCanProceed] = useState(true);
  const [showPostcodeError, setShowPostcodeError] = useState(false);
  const [showPostcodeNotListedError, setPostcodeNotListedError] = useState(false);
  const [addressSelected, setAddressSelected] = useState(false);

  const isAdditonalAddressAddEnabled = provenance === "additional_delivery_address";

  const characterCount = (values) => values.addressLabel?.length || 0;

  const [addUserAddress] = useMutation(ADD_USER_ADDRESS);

  const [getAddresses, { loading: addressesLoading, error: addressesError }] =
    useLazyQuery(GET_ADDRESSES, {
      onCompleted: (data) => {
        setPostcodeAttempted(true);
        setAddresses(data.addresses || []);
        setAddressSelected(false);
      },
      onError: (error) => {
        const code = error.graphQLErrors[0]?.extensions?.code;
        setPostcodeAttempted(false);
        setAddresses(null);
        sessionTimeoutLogout(error.message);
        if (code === "ADDRESS_THROTTLING") {
          setFindAddressThrottleError(true);
        }
      }
    });
  const [updateAddress] = useMutation(UPDATE_ADDRESS);
  const { loading: userLoading, data: userData, error: userError } =
    useQuery(GET_CURRENT_USER, {
      onCompleted: (data) => {
        if (data.me.mobile === null) {
          setMobileInput(true);
        }
      }
    });

  const { loading: userAddressLoading, data: userAddressData, error: userAddressError } =
   useQuery(GET_USER_ADDRESS,
     {variables:
       {
         guid: window.location.pathname?.split("/my-details/")[1]?.split("/edit")[0] || ''
       },
     onCompleted: (data) => {
       if (data.userAddress) {
         setCurrentGuid(data.userAddress.guid);
         if (isAdditonalAddressAddEnabled) {
            setAddresses([]);
            setPostcodeAttempted(false);
          } else {
            setAddresses(data.userAddress.addresses || []);
            setPostcodeAttempted(true);
            if (data.userAddress.manualAddress) {
              setManualEntry(true);
              setManualEntryInputs(true);
            }
          }
       }
     }
  });

  const [updateMobileNumber] = useMutation(UPDATE_USER_MOBILE);

  const updateUser = (address, mobile, actions) => {
    // we handle 3 different scenarios in this function
    // first, when a user proceeds, without selecting an address:
    setDisableSubmission(true);

    if (address === null) {
      if (!userData.me.mobile) {
        updateMobileNumber(
          {variables: {mobile: mobile, password: ''}}
        ).then(data => {
          const response = data.data && data.data.updateMobileNumber;
          if (response.unverifiedMobile) {
            window.location.href = `/my-details/mobile-verification`;
          } else {
            window.location.href = `/my-account/delivery-addresses`;
          }
        }).catch(error => {
          setDisableSubmission(false);
          sessionTimeoutLogout(error.message);
          setAddressError(error.message);
          const code = error.graphQLErrors[0]?.extensions?.code;
          if (code === "MOBILE_TOKEN_THROTTLING") {
            setMobileTokenThrottleError(true);
          }
        })
      } else {
        // fallback for when no address can be found for a postcode
        window.location.href = `/my-account/`;
      }
    } else if (editMode) {
      // second: when a user is editing one of his addresses:
      updateAddress(
        {variables: {
          address: address,
          addressGuid: currentGuid,
        }}
      )
      .then(result => {
        const addressResponse = result.data && result.data.updateAddress;

        if (!userData.me.mobile) {
          updateMobileNumber(
            {variables: {mobile: mobile, password: ''}}
          ).then(data => {
            const response = data.data && data.data.updateMobileNumber;
            if (response.unverifiedMobile) {
              window.location.href = `/my-details/mobile-verification`;
            } else if (addressResponse.user.userAddress?.guid && (address.uprn !== "not_listed")) {
              window.location.href = `/addresses/${addressResponse.userAddress.guid}/neighbours${editMode ? "?edit=true&provenance=delivery-preferences" : ""}`;
            } else if (address.uprn === "not_listed" && address.manualAddress === true) {
              window.location.href = '/my-account?updated=true&provenance=address_manually_added';
            } else if (address.uprn === "not_listed") {
              window.location.href = '/my-account?updated=true&provenance=address_not_changed';
            } else {
              window.location.href = `/my-account/delivery-addresses`;
            }
          }).catch(error => {
            setDisableSubmission(false);
            sessionTimeoutLogout(error.message);
            setAddressError(error.message);
            const code = error.graphQLErrors[0]?.extensions?.code;
            if (code === "MOBILE_TOKEN_THROTTLING") {
              setMobileTokenThrottleError(true);
            }
          })
          setCurrentGuid(addressResponse.userAddress.guid);
        } else {

          if (addressResponse.userAddress?.guid && (address.uprn !== "not_listed")) {
            window.location.href = `/addresses/${addressResponse.userAddress.guid}/neighbours${editMode ? "?edit=true&provenance=delivery-preferences" : ""}`;
          } else if (address.uprn === "not_listed" && address.manualAddress === true) {
            window.location.href = '/my-account?updated=true&provenance=address_manually_added';
          } else if (address.uprn === "not_listed") {
            window.location.href = '/my-account?updated=true&provenance=address_not_changed';
          } else {
            window.location.href = `/my-account/delivery-addresses`;
          }
        }
      })
      .catch(error => {
        setDisableSubmission(false);
        sessionTimeoutLogout(error.message);
        setAddressError(error.message);
      });
    } else {
      // third: when a user adds his "primary" address
      addUserAddress(
        {
          variables: {
            address: address
          }
        }
      )
      .then(data => {
        const addressDataResponse = data.data && data.data.addUserAddress;

        // there is an edge case, when the user already has a mobile before setting up his first address
        if (!userData?.me?.mobile) {
          updateMobileNumber(
            {variables: {mobile: mobile, password: ''}}
          ).then(addressData => {
            const response = addressData.data && addressData.data.updateMobileNumber;

            if (response.unverifiedMobile) {
              window.location.href = `/my-details/mobile-verification`;
            } else if (addressDataResponse.user.userAddress?.guid && (address.uprn !== "not_listed")) {
              window.location.href = `/addresses/${addressDataResponse.userAddress.guid}/neighbours${editMode ? "?edit=true&provenance=delivery-preferences" : ""}`;
            } else if (address.uprn === "not_listed" && address.manualAddress === true) {
              window.location.href = '/my-account?updated=true&provenance=address_manually_added';
            } else if (address.uprn === "not_listed") {
              window.location.href =  '/my-account?updated=true&provenance=address_not_changed';
            } else {
              window.location.href = `/my-account/delivery-addresses`;
            }
          }).catch(error => {
            setDisableSubmission(false);
            sessionTimeoutLogout(error.message);
            setAddressError(error.message);
            const code = error.graphQLErrors[0]?.extensions?.code;
            if (code === "MOBILE_TOKEN_THROTTLING") {
              setMobileTokenThrottleError(true);
            }
          })
          setEditMode(true);
          setCurrentGuid(addressDataResponse.userAddress.guid);
        } else if (addressDataResponse.userAddress?.guid && (address.uprn !== "not_listed")) {
          window.location.href = `/addresses/${addressDataResponse.userAddress.guid}/neighbours`;
        } else if (address.uprn === "not_listed" && address.manualAddress === true) {
          window.location.href = '/my-account?updated=true&provenance=address_manually_added';
        } else if (address.uprn === "not_listed") {
          window.location.href = '/my-account?updated=true&provenance=address_not_changed';
        } else {
          window.location.href = `/my-account/delivery-addresses`;
        }
      })
      .catch(error => {
        setDisableSubmission(false);
        sessionTimeoutLogout(error.message);
        setAddressError(error.message);
      });
    }
  };

  const onAddressSelectorChange = (options) => {
    setAddressSelected(true);
    setAddressNotSelectedError(false);
    setManualEntryInputs(false);
    if (options.uprn === "not_listed") {
      setManualEntry(true);
      setTimeout(() => setNotListedCanProceed(notListedAddressCanProceed()));
    } else {
      setManualEntry(false);
      setNotListedCanProceed(true);
    };
  }

  const handleSubmit = (values, actions) => {
    if (showManualEntryInputs &&
        (values?.address?.uprn === "not_listed" || (values.addressLine1 && values.town)) ) {
      setAddressNotSelectedError(false);
      const address = {
        addressLine1: values.addressLine1.trim(),
        addressLine2: values.addressLine2.trim(),
        town: values.town.trim(),
        county: values.county.trim(),
        postcode: values?.address?.postcode?.trim() || values.postcode.trim(),
        uprn: "not_listed",
        manualAddress: true
      }
      updateUser(address, values.mobile, actions);
    } else if (values.address) {
      setAddressNotSelectedError(false);
      const {__typename, addresses, ...address} =  values.address;
      delete address.guid;
      updateUser(address, values.mobile, actions);
    } else if (postcodeAttempted && (!addresses || addresses.length == 0)) {
      setAddressNotSelectedError(false);
      updateUser(null, values.mobile, actions);
    } else {
      setAddressNotSelectedError(true);
    }
  };

  const handleDeliveryAddressSubmit = (values, actions) => {
    let {__typename, addresses, ...address} =  values.address || {};

    if (!values?.address && !(values?.addressLine1 || values?.town || values?.postcode)) {
      setAddressNotSelectedError(true);
      return;
    }

    if (showManualEntryInputs &&
        (values?.address?.uprn === "not_listed" || (values.addressLine1 && values.town)) ) {
      address = {
        addressLine1: values.addressLine1.trim(),
        addressLine2: values.addressLine2.trim(),
        town: values.town.trim(),
        county: values.county.trim(),
        postcode: values?.address?.postcode?.trim() || values.postcode.trim(),
        uprn: "not_listed",
        manualAddress: true
      }
    }

    addUserAddress(
      {variables: {
        address: values.addressLabel ? Object.assign(address, {label: values.addressLabel}) : address,
      }}
    )
    .then(data => {
      const response = data.data && data.data.addUserAddress;
      if (response?.userAddress?.guid && (address.uprn !== "not_listed")) {
        window.location.href = `/addresses/${response?.userAddress?.guid}/neighbours${editMode ? "?edit=true&provenance=additional_delivery_address" : ""}`;
      } else if (address.uprn === "not_listed" && address.manualAddress === true) {
        window.location.href = '/my-account?updated=true&provenance=address_manually_added';
      } else if (address.uprn === "not_listed") {
        window.location.href = `/my-account/delivery-addresses?updated=true&provenance=additional_address_not_listed`;
      } else {
        window.location.href = `/my-account/delivery-addresses`;
      }
    })
    .catch(error => {
      sessionTimeoutLogout(error.message);
      setAddressError(error.message);
    });
  }

  let postCodeError = 'Postcode not recognised. Please try again.';

  let postCodeNotListedError = 'Please try again or add your address manually';

  const postcodeRegex = /^([A-PR-UWYZ](?:[0-9]{1,2}|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?|[0-9][A-HJKPS-UW]))([0-9][ABD-HJLNP-UW-Z]{2})?$/i;

  const handleFindAddress = (postcode, setFieldValue) => {
    if (postcode && !postcodeRegex.test(postcode.replace(/ /g,''))) {
      setShowPostcodeError(true);
      setPostcodeNotListedError(false);
      setManualEntry(false);
      setManualEntryInputs(false);
    } else {
      setShowPostcodeError(false);
      setPostcodeNotListedError(false);
    }

    setFieldValue("addressLine1", "", false);
    setFieldValue("addressLine2", "", false);
    setFieldValue("town", "", false);
    setFieldValue("county", "", false);
    setManualEntry(false);
    setManualEntryInputs(false);

    getAddresses(
      {variables: {postcode: postcode}}
    ).then(data => {
        if (postcodeRegex.test(postcode.replace(/ /g,'')) &&
            (data?.data?.addresses?.length === 0)) {
          setPostcodeNotListedError(true);
          setManualEntry(true);
        }
      });
  };

  const addressesWithNotListed = (addresses) => {
    const notListed = {
      addressLine1: "Address not listed",
      addressLine2: "",
      uprn: "not_listed",
      postcode: addresses[0].postcode
    };
    return [notListed].concat(addresses);
  };

  let addressLookupErrorMessage;

  if (findAddressThrottleError) {
    addressLookupErrorMessage = 'Maximum number of attempts reached, please try again later.';
  };

  const postCodeErrorMessage = () => {
    if (addressLookupErrorMessage) {
      return addressLookupErrorMessage;
    }

    if (showPostcodeError) {
      return postCodeError;
    }

    if (showPostcodeNotListedError) {
      return postCodeNotListedError;
    }
  };

  const MobileInputContainer = () => {
    return(
      <>
        <h2>Mobile number</h2>

        <div className="mobile-details-container">
          <TextField
            aria-label="Mobile number input"
            label="Mobile number"
            name="mobile"
            type="text"
            showValidMarker={false}
            errorMessage={mobileTokenThrottleError && 'Maximum number of attempts reached, please try again later.'}
            throttleError={mobileTokenThrottleError}
          />

          <div className="mobile-details-info-line">
            <img src="/assets/yodel-assets/blue-info-icon.svg" className="yodel-my-account-info-icon" alt="info-icon" />

            <p className="blue-paragraph">
              Your mobile number helps ensure your parcels appear in your account and aid delivery.
            </p>
          </div>
        </div>
      </>
    )
  }

  const notListedAddressCanProceed = () => {
    const addressInput = document.getElementById("address_line_one_input");
    const townInput = document.getElementById("town_input");
    return addressInput && townInput && addressInput.value.trim().length && townInput.value.trim().length;
  };

  const handleManualAddressChange = () => {
    setAddressSelected(true);
    setPostcodeNotListedError(false);
    setNotListedCanProceed(notListedAddressCanProceed());
  }

  return (
    !isAdditonalAddressAddEnabled ?
      (
        <Formik
          initialValues={{
            postcode: userAddressData?.userAddress?.postcode || '',
            address: userAddressData?.userAddress || '',
            mobile: userData && userData.me.mobile || '',
            addressLabel: '',
            addressLine1: userAddressData?.userAddress?.manualAddress ? (userAddressData?.userAddress?.addressLine1 || '') : '',
            addressLine2: userAddressData?.userAddress?.manualAddress ? (userAddressData?.userAddress?.addressLine2 || '') : '',
            town: userAddressData?.userAddress?.manualAddress ? (userAddressData?.userAddress?.town || '') : '',
            county: userAddressData?.userAddress?.manualAddress ? (userAddressData?.userAddress?.county || '') : '',
          }}
          enableReinitialize={true}
          validate={validateMobile}
          onSubmit={handleSubmit}
        >
          {({ values, errors, isValid, handleChange, setFieldValue }) => (
            <Form className="new_user my-details-address-form" noValidate>
              <h2>Address</h2>
            <p>
              Choose your delivery address to add delivery preferences to, and each time we deliver to this address we will automatically apply your preferences
            </p>
              {(addressError || userError || (addressesError && typeof addressesError !== 'object')) &&
                  <ErrorBanner error={addressError || userError || addressesError}/>}

              <div className={`my-details-address-finder ${!postcodeAttempted || !addresses?.length ? "-no-dropdown" : ""}`}>
                <TextField
                  aria-label="Postcode input"
                  label="Postcode"
                  name="postcode"
                  type="text"
                  showValidMarker={false}
                  errorMessage={postCodeErrorMessage()}
                  onChange={() => setFieldValue('address', null)}
                />
                <button
                  className="yodel-outline-button -fixed-height -mobile-one-line"
                  type="button"
                  onClick={() => handleFindAddress(values.postcode, setFieldValue)}
                  disabled={!values.postcode || userLoading || addressesLoading}
                >
                  Find address
                </button>
              </div>
              {postcodeAttempted && !!addresses?.length && (
                <div className="yodel-form-element-container">
                  <YodelDropdown
                    aria-label="Addresses"
                    options={addressesWithNotListed(addresses)}
                    labelField="addressLine1"
                    labelFieldExtra="addressLine2"
                    valueField="uprn"
                    id="my-details-address"
                    name="address"
                    unselectedLabel="Select Address"
                    isMobile={isMobile}
                    clearInput={true}
                    throttleError={findAddressThrottleError}
                    onChange={onAddressSelectorChange}
                    customClass={addressNotSelectedError}
                    onClearInput={() => {setManualEntry(false); setManualEntryInputs(!showManualEntryInputs);}}
                  />
                </div>
              )}

              {addressNotSelectedError &&
                <p className="validation_error -negative-margin">
                  Please select an address or address not listed
                </p>
              }

              {
                /*
                  Tech debt: We are repeating ourselves here, because if we move this component
                  outside of Formik it gets rerendered on state changes and one of the mandatory
                  inputs loses focus.
                  Tried various solutions from various sources (SO, GH, Formik recommended examples),
                  but nothing worked except this.
                  Should be DRY-ed up later.
                */

                isManualEntryEnabled &&
                  <>
                    <button className={`yodel-outline-button -fixed-height -full-width ${!showManualEntryInputs ? "-extra-margin-bottom" : "-hidden"}`}
                      type="button"
                      onClick={() => {
                        setManualEntryInputs(!showManualEntryInputs);
                        setTimeout(() => setNotListedCanProceed(notListedAddressCanProceed()));
                      }}>
                      Add address manually
                    </button>

                    {showManualEntryInputs &&
                      <div className="yodel-manual-address-entry">
                        <div className="mobile-details-info-line">
                          <img src="/assets/yodel-assets/blue-info-icon.svg" className="yodel-my-account-info-icon" alt="info-icon" />

                          <p className="blue-paragraph">
                            Neighbour and Safe Place preferences are unavailable for manually entered addresses.
                          </p>
                        </div>

                        <TextField
                          aria-label="Address line 1"
                          label="Address line 1*"
                          name="addressLine1"
                          type="text"
                          showValidMarker={false}
                          onKeyUp={handleManualAddressChange}
                          id="address_line_one_input"
                        />
                        <TextField
                          aria-label="Address line 2"
                          label="Address line 2"
                          name="addressLine2"
                          type="text"
                          showValidMarker={false}
                        />
                        <TextField
                          aria-label="Town/City"
                          label="Town/City*"
                          name="town"
                          type="text"
                          showValidMarker={false}
                          onKeyUp={handleManualAddressChange}
                          id="town_input"
                        />
                        <TextField
                          aria-label="County"
                          label="County"
                          name="county"
                          type="text"
                          showValidMarker={false}
                        />
                      </div>
                    }
                  </>
              }

              {
                isUserMobileSet ?  <MobileInputContainer /> : null
              }

              <button
                className={`yodel-faq-button register-signup-btn -with-chevron -centered-chevron -end-of-block-bottom-margin`}
                type="submit"
                name="submit"
                disabled={isSubmitting || !values?.mobile || !postcodeAttempted || !notListedCanProceed || showPostcodeError || showPostcodeNotListedError || disableSubmission}>
                {"Next"}
                <YodelChevron className="yodel-faq-button__chevron"></YodelChevron>
              </button>
            </Form>
          )}
        </Formik>
      ) :
      (
        <Formik
          initialValues={{
            postcode: '',
            address: '',
            addressLabel: '',
            addressLine1: '',
            addressLine2: '',
            town: '',
            county: '',
          }}
          enableReinitialize={true}
          onSubmit={handleDeliveryAddressSubmit}>

          {({ values, errors, isValid, handleChange, setFieldValue }) => (
            <Form className="new_user my-details-address-form" noValidate>
              <h2>Address</h2>
            <p>
              Choose your delivery address to add delivery preferences to, and each time we deliver to this address we will automatically apply your preferences
            </p>
              {(addressError || (userError && typeof userError !== 'object') ||
                (addressesError && typeof addressesError !== 'object')) &&
                  <ErrorBanner error={addressError || userError || addressesError}/>}

              <div className={`my-details-address-finder ${!postcodeAttempted || !addresses?.length ? "-no-dropdown" : ""}`}>
                <TextField
                  aria-label="Postcode input"
                  label="Postcode"
                  name="postcode"
                  type="text"
                  showValidMarker={false}
                  errorMessage={postCodeErrorMessage()}
                  onChange={() => setFieldValue('address', null)}
                />
                <button
                  className="yodel-outline-button -fixed-height -mobile-one-line"
                  type="button"
                  onClick={() => handleFindAddress(values.postcode, setFieldValue)}
                  disabled={!values.postcode || userLoading || addressesLoading}
                >
                  Find address
                </button>
              </div>
              {postcodeAttempted && !!addresses?.length && (
                <div className="yodel-form-element-container">
                  <YodelDropdown
                    aria-label="Addresses"
                    options={addressesWithNotListed(addresses)}
                    labelField="addressLine1"
                    labelFieldExtra="addressLine2"
                    valueField="uprn"
                    id="my-details-address"
                    name="address"
                    unselectedLabel="Select Address"
                    isMobile={isMobile}
                    clearInput={true}
                    throttleError={findAddressThrottleError}
                    onChange={onAddressSelectorChange}
                    customClass={addressNotSelectedError}
                    onClearInput={() => {
                      setAddressSelected(false);
                      setManualEntry(false);
                    }}
                  />
                </div>
              )}

              {addressNotSelectedError &&
                <p className="validation_error -negative-margin">
                  Please select an address or address not listed
                </p>
              }

              {
                isManualEntryEnabled &&
                  <>
                    <button className={`yodel-outline-button -fixed-height -full-width ${!showManualEntryInputs ? "-extra-margin-bottom" : "-hidden"}`}
                      type="button"
                      onClick={() => {
                        setManualEntryInputs(!showManualEntryInputs);
                        setTimeout(() => setNotListedCanProceed(notListedAddressCanProceed()));
                      }}>
                      Add address manually
                    </button>

                    {showManualEntryInputs &&
                      <div className="yodel-manual-address-entry">
                        <div className="mobile-details-info-line">
                          <img src="/assets/yodel-assets/blue-info-icon.svg" className="yodel-my-account-info-icon" alt="info-icon" />

                          <p className="blue-paragraph">
                            Neighbour and Safe Place preferences are unavailable for manually entered addresses.
                          </p>
                        </div>

                        <TextField
                          aria-label="Address line 1"
                          label="Address line 1*"
                          name="addressLine1"
                          type="text"
                          showValidMarker={false}
                          onKeyUp={handleManualAddressChange}
                          id="address_line_one_input"
                        />
                        <TextField
                          aria-label="Address line 2"
                          label="Address line 2"
                          name="addressLine2"
                          type="text"
                          showValidMarker={false}
                        />
                        <TextField
                          aria-label="Town/City"
                          label="Town/City*"
                          name="town"
                          type="text"
                          showValidMarker={false}
                          onKeyUp={handleManualAddressChange}
                          id="town_input"
                        />
                        <TextField
                          aria-label="County"
                          label="County"
                          name="county"
                          type="text"
                          showValidMarker={false}
                        />
                      </div>
                    }
                  </>
              }

              <h2>Label</h2>

              <p>
                This can be the name of the addressee or what the location is e.g Home, Work, Mum.
              </p>

              <Field
                name="addressLabel"
                rows="3"
                placeholder="Please type..."
                as="textarea"
                className="safe-place-instructions-input"
                maxLength="50"
              />

              <span className="address-label-character-count">
                {characterCount(values)}/50 characters
              </span>

              <button
                className={`yodel-faq-button register-signup-btn -with-chevron -centered-chevron -end-of-block-bottom-margin`}
                type="submit"
                name="submit"
                disabled={!addressSelected || addressLookupErrorMessage || isSubmitting || !postcodeAttempted ||  !notListedCanProceed ||  showPostcodeError || showPostcodeNotListedError || disableSubmission}>
                Next
                <YodelChevron className="yodel-faq-button__chevron"></YodelChevron>
              </button>
            </Form>
          )}
        </Formik>
      )
  )
};

export default MyDetailsPage;
