import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { gql, useMutation, useQuery } from '@apollo/client';
import ErrorBanner from '../my_account/error_banner';
import PasswordField from './password_field';
import { sessionTimeoutLogout } from './session_timeout_logout_helper';
import AccountDetailsHeadline from './account_details_headline';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      id
      email
      userIsSsoUser
    }
  }
`;

const CHANGE_USER_PASSWORD = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      message
      errors
    }
  }
`;

const AddUserPasswordPage  = ({isMobile}) => {
  const { loading, data: userData } = useQuery(GET_CURRENT_USER);
  const [changeUserPassword, { data }] = useMutation(CHANGE_USER_PASSWORD);
  const [error, setError] = useState(null);

  const handleSubmit = (values, actions) => {
    changeUserPassword(
      {variables: {
        newPassword: values.password,
        currentPassword: "",
      }}
    )
    .then(data => {
      const response = data.data?.changePassword;
      if (response.message) {
        window.location.href = "/my-account/change-email-address";
      } else {
        actions.setSubmitting(false);
        setError(response.errors[0]);
      }
    })
    .catch(error => {
      actions.setSubmitting(false);
      sessionTimeoutLogout(error.message);
      setError(error.message);
    });
  };

  return (
    <>
      <div className="section-content yodel-reset-password-container -narrow -flex-column">
        <AccountDetailsHeadline backLink="/my-account/manage-email-addresses" headline="Create a password" />

        <div className="yodel-reset-password-content">
          <p className="blue-paragraph -bold">Just so you know...</p>
          <div className="bordered-container">
            <p>
              As you will no longer be able to log in via social media, please create a password.
            </p>
          </div>
          {error && <ErrorBanner error={error}/>}
          <Formik
            initialValues={{
              password: '',
            }}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting }) => (
              <Form className="reset-password-form">
                <PasswordField label="Password" name="password" simpleValidation />
                <input
                  type="submit"
                  name="submit"
                  value="Save"
                  className="yodel-outline-button reset-password-btn"
                  disabled={isSubmitting}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddUserPasswordPage;
