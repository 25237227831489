import React, { useState } from 'react';
import { useField } from 'formik';
import {passwordErrors, validatePassword} from './validation';
import PasswordStrength from '@fnando/password_strength';

const PasswordField = ({ label, simpleValidation, hideValidation, hidePwStrengthBar, ...props}) => {
  const [field, meta, helpers] = useField(props);
  const [focused, setFocused] = useState(false);
  const [visible, setVisible] = useState(false);
  const [inputValue, setInput] = useState('');
  const [populatedField, setPopulatedField] = useState(false);
  const imagePath = {
    hidden: "/assets/yodel-assets/password-hidden.svg",
    shown: "/assets/yodel-assets/password-show.svg"
  }

  const onFocus = () => {
    setFocused(true);
  }

  const onBlur = () => {
    setFocused(false);
    helpers.setTouched(true);
  }

  const onClick = () => {
    setVisible(!visible);
  }

  const classForValidationCheck = (validationType) => {
    const invalid =
      validatePassword(meta.value).includes(passwordErrors[validationType]);
    return `field-is-correct${invalid ? ' -gray' : ''}`
  }

  const PwStrengthBar = (props) => {
    let strengthText;
    let strengthBarStyle;
    let strength = PasswordStrength.test("", props.value);

    if (props.value.length) {
      switch (strength.status) {
        case "good":
          strengthText = "Good";
          strengthBarStyle = "-good";
          break;
        case "strong":
          strengthText = "Strong";
          strengthBarStyle = "-strong";
          break;
        default:
          strengthText = "Weak";
          strengthBarStyle = "-weak";
          break;
      }

      return (
        <div>
          <p className={`pw-strength-bar ${strengthBarStyle}`}></p>
          <p className="pw-strength-label">{strengthText}</p>
        </div>
      )
    } else {
      return null;
    }
  }

  return (
    <div className="branded-form-element-container -yodel">
      <label className="label-branded" htmlFor={props.name}>
        <input
          type={visible ? "text" : "password"}
          className={`${meta.touched && meta.error ?  '-red-border' : ''} input-branded -gray`}
          {...field} {...props}
          onFocus={onFocus}
          onBlur={onBlur}
          onAnimationStart={(e) => setPopulatedField(e.animationName === "onAutoFillStart")}
          value={inputValue}
          onInput={e => setInput(e.target.value)} />
        <span className={`label-branded-text${ populatedField ? " -on-top" : ""}`}>{label}</span>
        <img
          className="password-show-btn"
          src={imagePath[visible ? 'shown' : 'hidden']}
          alt="password-show-icon"
          onClick={onClick} />
      </label>

      {simpleValidation && meta.touched && meta.error && (
        <p className="validation_error">{meta.error}</p>
      )}

      {!hidePwStrengthBar && (
        <PwStrengthBar value={inputValue} />
      )}

      {!hideValidation && (
        <>
          <div className="password-validation-check-line">
            <span className={classForValidationCheck('alphanumeric')} />
            {passwordErrors.alphanumeric}
          </div>
          <div className="password-validation-check-line">
            <span className={classForValidationCheck('lowerCase')} />
            {passwordErrors.lowerCase}
          </div>
          <div className="password-validation-check-line">
            <span className={classForValidationCheck('upperCase')} />
            {passwordErrors.upperCase}
          </div>
          <div className="password-validation-check-line">
            <span className={classForValidationCheck('minLength')} />
            {passwordErrors.minLength}
          </div>
        </>
      )}
    </div>
  );
}

export default PasswordField;
