import React, {useState, useEffect} from 'react';

const ActivationEmailExpired  = ({isMobile, userId}) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]') &&
   document.querySelector('meta[name="csrf-token"]').getAttribute("content");

  return (
    <div className={(isMobile ? "" : "-with-border ") + "signup-content"}>
      <div className="expiry-line">
        <img src="/assets/yodel-assets/timer-icon-red.svg" alt="timer-icon" />
        <p className="blue-paragraph">Verification link expired</p>
      </div>

      <ul className="email-verification-list -third-list-item-empty">
        <li>Sorry, the link to verify your email address has expired. </li>
        <li>
          The link is only valid for 30 minutes. You can request a new email with a verification link.
        </li>
        <li>
          <form className="resend-verification-form" action={`/users/${userId}/activation`}  method="post" acceptCharset="UTF-8">
            <input type="hidden" name="authenticity_token" value={csrfToken ||  ''} />
            <input
              type="submit"
              name="submit"
              value="Resend verification email"
              className="resend-verification-link"
            />
          </form>
        </li>
      </ul>
    </div>
  );
};

export default ActivationEmailExpired;
