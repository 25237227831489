import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useLazyQuery, useMutation, gql } from "@apollo/client";
import { Formik, Form } from 'formik';
import TextField from './text_field';
import YodelDropdown from './yodel_dropdown';
import ErrorBanner from '../my_account/error_banner';
import YodelChevron from '../my_account/yodel_chevron';
import { sessionTimeoutLogout } from './session_timeout_logout_helper';
import checkURLParams from "./check_url_params";

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      unverifiedMobile
    }
  }
`;

const VERIFY_MOBILE_NUMBER = gql`
  mutation VerifyMobileNumber($token: String!) {
    verifyMobileNumber(token: $token) {
      user {
        userAddress {
          guid
          manualAddress
        }
      }
    }
  }
`;

const UPDATE_MOBILE_NUMBER = gql`
  mutation UpdateMobileNumber($mobile: String!, $password: String) {
    updateMobileNumber(mobile: $mobile, password: $password) {
      message
    }
  }
`;

const MobileVerificationPage = ({ isMobile }) => {
  const [tokenError, setTokenError] = useState(null);
  const [isCodeFormSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setNewCodeSubmittedSuccess] = useState(null);
  const [updateMobileNumber, { data }] =
    useMutation(UPDATE_MOBILE_NUMBER);

  const { loading: userLoading, data: userData, error: userError } =
    useQuery(GET_CURRENT_USER);

  const [verifyMobileNumber] = useMutation(VERIFY_MOBILE_NUMBER);

  const isMobileNumberEdit = checkURLParams("edit");

  const formikRef = useRef();

  const handleOnKeyUp = (e) => {
    let key = e.which;
    let nextInput = e.target.nextElementSibling;

    // key 9 is backspace
    if (key !== 9 && (key < 48 || key > 57) && key !== 192) {
      e.preventDefault();
      return false;
    }

    if (key === 9) {
      return true;
    }

    nextInput?.focus();
    nextInput?.select();
  }

  const handleOnKeyDown = (e) => {
    let key = e.which;

    if (key === 9 || (key >= 48 && key <= 57) || key === 192) {
      return true;
    }

    e.preventDefault();
    return false;
  }

  const handleClick = (e) => { e.target.select(e); }

  const handleNewCodeSubmit = (values, actions) => {
    updateMobileNumber(
      { variables: { mobile: userMobileNumber, password: ''} }
    )
      .then(data => {
        const response = data.data && data.data.updateMobileNumber;

        setSubmitting(false);
        setTokenError(false);
        formikRef.current?.resetForm();
        setNewCodeSubmittedSuccess(response.message);
      })
      .catch(error => {
        setSubmitting(false);
        setError(error.message);
        scrollToErrorBanner();
      })
  }

  const handleSubmit = (values, actions) => {
    const token = values.input0 + values.input1 + values.input2 + values.input3 +
      + values.input4 + values.input5;

    setNewCodeSubmittedSuccess(false);

    verifyMobileNumber(
      { variables: { token: token } }
    )
      .then(data => {
        const response = data.data && data.data.verifyMobileNumber;
        if (isMobileNumberEdit) {
          window.location.href = `/my-account/account-details?updated=true&provenance=mobile-number-changed`;
        } else if (response.user.userAddress?.guid && !response.user.userAddress?.manualAddress) {
          window.location.href = `/addresses/${response.user.userAddress?.guid}/neighbours`;
        } else if (response.user.userAddress?.manualAddress) {
          window.location.href = '/my-account?updated=true&provenance=address_manually_added';
        } else {
          window.location.href = `/my-account?updated=true&provenance=address_not_changed`;
        }
      })
      .catch(error => {
        actions.setSubmitting(false);
        sessionTimeoutLogout(error.message);
        setTokenError(error.message);
      });
  };

  const SuccessBanner = ({ success }) => {
    const [show, setShow] = useState(true);

    useEffect(() => {
      const timeId = setTimeout(() => {
        setShow(false)
      }, 5000)

      return () => {
        clearTimeout(timeId)
      }
    }, []);

    if (!show) {
      return null;
    } else {
      return (
        <div className="react-flash-message -password-updated -verification-sent">
          {success}
        </div>
      )
    }
  }


  const userMobileNumber = userData?.me?.unverifiedMobile || "";

  const prettyMobileNumber = userMobileNumber.substr(0, 3) + " " + userMobileNumber.substr(3, 4) + " " + userMobileNumber.substr(7, 6);

  return (
    <div className="mobile-verification-container">
      {success && <SuccessBanner success={success} />}
      <p className="blue-paragraph -bold">Verify your mobile number</p>

      <div className="bordered-container">
        <p>
          A text message with a code has been sent to {prettyMobileNumber}.
        </p>
      </div>
      <div className="expiry-line">
        <img src="/assets/yodel-assets/timer-icon.svg" alt="timer-icon" />
        <p>This will expire after 30 minutes. </p>
      </div>

      <p className="blue-paragraph -bold">Enter your code here</p>

      {(tokenError) && <ErrorBanner error={tokenError} />}

      <Formik
        initialValues={{
          input0: '',
          input1: '',
          input2: '',
          input3: '',
          input4: '',
          input5: ''
        }}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({ isSubmitting, values, errors, isValid,
          handleChange, setFieldValue, getFieldProps }) => (
          <Form className="my-details-address-form -mobile-validation" noValidate>
            <div className="mobile-validation-digit-inputs">
              <input type="text" maxLength="1" size="1"
                min="0" max="9" pattern="[0-9]{1}" key="0" name={"input0"}
                {...getFieldProps("input0")} onKeyUp={handleOnKeyUp}
                onKeyDown={handleOnKeyDown} onClick={handleClick}
                id="input-digit-0" autoComplete="off" />
              <input type="text" maxLength="1" size="1"
                min="0" max="9" pattern="[0-9]{1}" key="1" name={"input1"}
                {...getFieldProps("input1")} onKeyUp={handleOnKeyUp}
                onKeyDown={handleOnKeyDown} onClick={handleClick}
                id="input-digit-1" autoComplete="off" />
              <input type="text" maxLength="1" size="1"
                min="0" max="9" pattern="[0-9]{1}" key="2" name={"input2"}
                {...getFieldProps("input2")} onKeyUp={handleOnKeyUp}
                onKeyDown={handleOnKeyDown} onClick={handleClick}
                id="input-digit-2" autoComplete="off" />
              <input type="text" maxLength="1" size="1"
                min="0" max="9" pattern="[0-9]{1}" key="3" name={"input3"}
                {...getFieldProps("input3")} onKeyUp={handleOnKeyUp}
                onKeyDown={handleOnKeyDown} onClick={handleClick}
                id="input-digit-3" autoComplete="off" />
              <input type="text" maxLength="1" size="1"
                min="0" max="9" pattern="[0-9]{1}" key="4" name={"input4"}
                {...getFieldProps("input4")} onKeyUp={handleOnKeyUp}
                onKeyDown={handleOnKeyDown} onClick={handleClick}
                id="input-digit-4" autoComplete="off" />
              <input type="text" maxLength="1" size="1"
                min="0" max="9" pattern="[0-9]{1}" key="5" name={"input5"}
                {...getFieldProps("input5")} onKeyUp={handleOnKeyUp}
                onKeyDown={handleOnKeyDown} onClick={handleClick}
                id="input-digit-5" autoComplete="off" />
            </div>

            <button
              className={`yodel-faq-button -with-chevron -centered-chevron`}
              type="submit"
              name="submit"
              disabled={isSubmitting}>
              Next
              <YodelChevron className="yodel-faq-button__chevron"></YodelChevron>
            </button>
          </Form>
        )}
      </Formik>

      <p className="blue-paragrap -bold">Not received your text?</p>

      <ul className="email-verification-list">
        <li>
          <a href={isMobileNumberEdit ? "/my-account/change-mobile-number" : "/my-details"}>
            I’ve entered the wrong mobile number
          </a>
        </li>
        <li>
          {isMobileNumberEdit ?
            <a href="/my-account/change-mobile-number">
              I need a new code
            </a>
            :
            <Formik
              initialValues={{}}
              onSubmit={handleNewCodeSubmit}
            >
              {({ isCodeFormSubmitting, errors }) => (
                <Form className="mobile-verification-new-code-form">
                  <button className={`new-code-form-link`}
                    type="submit"
                    name="submit"
                    disabled={isCodeFormSubmitting}>
                    I need a new code
                  </button>
                </Form>
              )
              }
            </Formik>
          }
        </li>
      </ul>
    </div>
  )
};

export default MobileVerificationPage;
