import React, { useState } from 'react';
import { useField } from 'formik';

const TextField = ({ label, errorMessage, showValidMarker = true, onChange, throttleError = false, capitaliseFirstLetter = false, ...props}) => {
  const [field, meta] = useField(props);
  const [populatedField, setPopulatedField] = useState(false);
  const inputClassName = (isInValid) => {
    return `${(isInValid || errorMessage) ?  '-red-border' : ''} input-branded -gray`;
  }

  const handleChange = (e) => {
    field.onChange(e);
    onChange?.call(e);
  }

  const capitaliseNames = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <div className="branded-form-element-container -yodel">
      <label className="label-branded" htmlFor={props.name}>
        <input
          className={`${inputClassName(meta.touched && meta.error)} ${throttleError ? '-red-border' : null}`}
          onAnimationStart={(e) => setPopulatedField(e.animationName === "onAutoFillStart")}
          value={capitaliseFirstLetter ? capitaliseNames(field.value) : field.value}
          {...props} onChange={handleChange}
        />
        <span className={`label-branded-text${ populatedField ? " -on-top" : ""}`}>{label}</span>
        {meta.touched && !meta.error && showValidMarker ? (
          <span className="field-is-correct -absolute"></span>
        ) : null}
      </label>
      {errorMessage || (meta.touched && meta.error) ? (
        <p className="validation_error">{errorMessage || meta.error}</p>
      ) : null}
    </div>
  );
}

export default TextField;
