import React from 'react';
import { Formik } from 'formik';
import TextField from './text_field';
import PasswordField from './password_field';

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Please enter an email address';
  }

  if (!values.password) {
    errors.password = 'Please enter a password';
  }

  return errors;
}

const LoginForm = () => {
  const handleSubmit = (event, formikProps) => {
    const errors = validate(formikProps.values);
    if (Object.keys(errors).length > 0) {
      event.preventDefault();
      formikProps.setTouched({email: true, password: true});
    }
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validate={validate}
    >
      {(formikProps) => (
        <form onSubmit={(event) => handleSubmit(event, formikProps)}
          className="user-sign-in-form"
          id="new_user_session"
          action="/user_sessions"
          acceptCharset="UTF-8"
          method="post"
          noValidate
        >
          <TextField
            label="Email address"
            name="email"
            type="email"
            showValidMarker={false}
          />
          <PasswordField
            label="Password"
            name="password"
            simpleValidation={true}
            hideValidation={true}
            hidePwStrengthBar={true}
          />
          <a className="forgot-password-link" href="/reset_password/new" rel="noopener noreferrer">
            Forgot your password or don’t have one yet?
          </a>
          <input
            type="submit"
            name="submit"
            value="Log in"
            className="yodel-outline-button register-signup-btn"
          />
        </form>
      )}
    </Formik>
  );
}

export default LoginForm;
