import React, {useState, useEffect} from 'react';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import ErrorBanner from '../my_account/error_banner';
import PasswordField from './password_field';
import {passwordErrors, validateConfirmNewPassword, validatePassword} from './validation';
import {sessionTimeoutLogout} from './session_timeout_logout_helper';

const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword($password: String!) {
    resetUserPassword(password: $password) {
      message
      errors
    }
  }
`;

const ResetPasswordConfirmation  = ({isMobile}) => {
  const [error, setError] = useState(null);
  const [resetUserPassword, { data }] = useMutation(RESET_USER_PASSWORD);

  const handleSubmit = (values, actions) => {
    resetUserPassword(
      {variables: {password: values.password}}
    )
    .then(data => {
      const response = data.data?.resetUserPassword;
      if (response.message) {
        window.location.href = '/my-account'
      } else {
        actions.setSubmitting(false);
        setError(response.errors[0]);
        scrollToErrorBanner();
      }
    })
    .catch(error => {
      actions.setSubmitting(false);
      sessionTimeoutLogout(error.message);
      setError(error.message);
      scrollToErrorBanner();
    });
  };

  const scrollToErrorBanner = () => {
    document.getElementsByClassName("yodel-registration-error-banner")[0]
      .scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const classForValidationCheck = (validationType, value) => {
    const invalid =
      validatePassword(value).includes(passwordErrors[validationType]);
    return `field-is-correct${invalid ? ' -gray' : ''}`
  }

  return (
    <div className="section-content yodel-reset-password-container -narrow">
      <div className="yodel-reset-password-content">
        <p>Create a new password</p>
        {error && <ErrorBanner error={error}/>}
        <Formik
          initialValues={{
              password: '',
          }}
          validate={validateConfirmNewPassword}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting }) => (
            <Form className="reset-password-form">
              <PasswordField label="New password" name="password" simpleValidation hideValidation={true} />
                <div className="password-validation-container">
                  <div className="password-validation-check-line">
                    <span className={classForValidationCheck('alphanumeric', values.password)} />
                    {passwordErrors.alphanumeric}
                  </div>
                  <div className="password-validation-check-line">
                    <span className={classForValidationCheck('lowerCase', values.password)} />
                    {passwordErrors.lowerCase}
                  </div>
                  <div className="password-validation-check-line">
                    <span className={classForValidationCheck('upperCase', values.password)} />
                    {passwordErrors.upperCase}
                  </div>
                  <div className="password-validation-check-line">
                    <span className={classForValidationCheck('minLength', values.password)} />
                    {passwordErrors.minLength}
                  </div>
                </div>
              <input
                type="submit"
                name="submit"
                value="Confirm"
                className="yodel-outline-button reset-password-btn"
                disabled={isSubmitting || !values?.password}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPasswordConfirmation;
