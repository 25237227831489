import React, {useState, useEffect} from 'react';
import RegistrationForm from '../my_account/registration_form';
import SocialSSO from '../my_account/social_sso';
import ErrorBanner from '../my_account/error_banner';

const RegistrationPage  = ({isMobile, onSuccess}) => {
  const [error, setError] = useState(null);

  let ssoErrorMessage = null;
  let ssoError = document.querySelector("[data-sso-error]").dataset.ssoError;
  if (ssoError == "true") {
    ssoErrorMessage = "Sorry, there has been a problem. Please try again.";
  }

  return (
    <>
      <div className={(isMobile ? "" : "-with-border") + " signup-content"} >
        <p>Create an account and be in control of all your Yodel deliveries.</p>

        {isMobile && (
          <>
            <SocialSSO isMobile={true} isSignUp={true} ssoError={ssoErrorMessage} />
            <div className="login-options-separator">
              <span>or</span>
            </div>
          </>
        )}

        {error && <ErrorBanner error={error} timeoutEnabled={true} />}
        <RegistrationForm
          onError={(error) => setError(error)}
          resetError={() => setError(null)}
          onSuccess={(email, id) => onSuccess(email, id)}
        />
      </div>
      {!isMobile && <SocialSSO isSignUp={true} ssoError={ssoErrorMessage} />}
    </>
  );
};

export default RegistrationPage;
