import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import ErrorBanner from '../my_account/error_banner';
import PasswordField from './password_field';
import { passwordErrors, validateConfirmNewPassword, validatePassword } from './validation';
import { sessionTimeoutLogout } from './session_timeout_logout_helper';
import AccountDetailsHeadline from './account_details_headline';
import checkURLParams from './check_url_params';

const CHANGE_USER_PASSWORD = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      message
      errors
    }
  }
`;

const ChangePassword  = ({isMobile}) => {
  const [error, setError] = useState(null);
  const [changePassword, { data }] = useMutation(CHANGE_USER_PASSWORD);

  const handleSubmit = (values, actions) => {
    changePassword(
      {variables: {
        currentPassword: values.currentPassword,
        newPassword: values.password,
      }}
    )
    .then(data => {
      const response = data.data?.changePassword;
      if (response.message) {
        if (checkURLParams('reset_workflow')) {
          window.location.href = '/my-details';
          return;
        }
        window.location.href = '/my-account?updated=true&provenance=password-change';
      } else {
        actions.setSubmitting(false);
        setError(response.errors[0]);
        scrollToErrorBanner();
      }
    })
    .catch(error => {
      actions.setSubmitting(false);
      sessionTimeoutLogout(error.message);
      setError(error.message);
      scrollToErrorBanner();
    });
  };

  const scrollToErrorBanner = () => {
    document.getElementsByClassName("yodel-registration-error-banner")[0]
      .scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const classForValidationCheck = (validationType, value) => {
    const invalid =
      validatePassword(value).includes(passwordErrors[validationType]);
    return `field-is-correct${invalid ? ' -gray' : ''}`
  }

  const validate = (values) => {
    const errors = validateConfirmNewPassword(values);

    if (!values.currentPassword) {
      errors.currentPassword = 'Please enter a password';
    }

    return errors;
  }

  const isAccountDetailsThereferrer = document.referrer.includes("/my-account/account-details");

  return (
    <>
      <div className={`${isAccountDetailsThereferrer ? "-flex-column" : null} section-content yodel-reset-password-container -narrow`}>
        {isAccountDetailsThereferrer && <AccountDetailsHeadline backLink="/my-account/account-details" headline="Change password" />}

        <div className="yodel-reset-password-content">
          {!isAccountDetailsThereferrer && <p>Change password</p>}
          {error && <ErrorBanner error={error}/>}
          <Formik
            initialValues={{
                currentPassword: '',
                password: '',
            }}
            validate={validate}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting }) => (
              <Form className="reset-password-form">
                <PasswordField label="Current password" name="currentPassword" simpleValidation hideValidation={true} hidePwStrengthBar={true} />
                <PasswordField label="New password" name="password" simpleValidation hideValidation={true} />
                <div className="password-validation-container">
                  <div className="password-validation-check-line">
                    <span className={classForValidationCheck('alphanumeric', values.password)} />
                    {passwordErrors.alphanumeric}
                  </div>
                  <div className="password-validation-check-line">
                    <span className={classForValidationCheck('lowerCase', values.password)} />
                    {passwordErrors.lowerCase}
                  </div>
                  <div className="password-validation-check-line">
                    <span className={classForValidationCheck('upperCase', values.password)} />
                    {passwordErrors.upperCase}
                  </div>
                  <div className="password-validation-check-line">
                    <span className={classForValidationCheck('minLength', values.password)} />
                    {passwordErrors.minLength}
                  </div>
                </div>
                <input
                  type="submit"
                  name="submit"
                  value="Save"
                  className="yodel-outline-button reset-password-btn"
                  disabled={isSubmitting}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
