import React, {useEffect, useState} from 'react';

const ErrorBanner = ({error, timeoutEnabled = false}) => {
  const [show, setShow] = useState(true);

  if (timeoutEnabled) {
    useEffect(() => {
      console.log("running timeout");
      const timeId = setTimeout(() => {
        console.log("timeout done!");
        setShow(false)
      }, 10000)

      return () => {
        clearTimeout(timeId)
      }
    }, []);
  }

  return (
    (show && typeof error !== 'object' &&
      <div className="yodel-registration-error-banner">
        <img src="/assets/yodel-assets/warning-triangle.svg" alt="warning-icon" />
        <p>{error}</p>
      </div>
    )
  )
};

export default ErrorBanner;
