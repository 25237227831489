const validate = (values) => {
  const emailRegex = /^(?!.*\.@|.*\.{2})[a-zA-Z0-9-]+[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]*@[a-zA-Z0-9-]+\.+([a-zA-Z0-9-]{2,})+(\.+([a-zA-Z0-9-]{2,})+)*$/;

  const errors = {};

  if (!values.firstname) {
    errors.firstname = 'Please enter a first name';
  }

  if (!values.surname) {
    errors.surname = 'Please enter a last name';
  }

  if (!values.email) {
    errors.email = 'Please enter an email address';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Please enter a valid email address';
  }

  if (!values.password) {
    errors.password = 'Please enter a password';
  } else if (validatePassword(values.password).length) {
    errors.password = 'Please enter a valid password';
  }

  if (!values.yodel_terms_of_service) {
    errors.yodel_terms_of_service =
      'Please accept terms and conditions to proceed';
  }

  return errors;
};

const simpleTextRegex = /^[A-Za-z0-9 .,*#\-]*$/;

const validatePassword = (value) => {
  const errors = [];

  if (/^([^0-9]*|[^a-zA-Z]*)$/.test(value)) {
    errors.push(passwordErrors.alphanumeric);
  }

  if (/^[^a-z]*$/.test(value)) {
    errors.push(passwordErrors.lowerCase);
  }

  if (/^[^A-Z]*$/.test(value)) {
    errors.push(passwordErrors.upperCase);
  }

  if (!/^.{8,}$/.test(value)) {
    errors.push(passwordErrors.minLength);
  }

  return errors;
};

const validateConfirmNewPassword = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = 'Please enter a password';
  } else if (validatePassword(values.password).length) {
    errors.password = 'Please enter a valid password';
  }

  return errors;
};

const passwordErrors = {
  alphanumeric: "Combination of letters and numbers",
  lowerCase: "At least one lowercase letter",
  upperCase: "At least one upper case letter",
  minLength: "Minimum eight characters"
};

const validateMobile = (values) => {
  const mobileRegex = /^((\(?(00|\+)\s?44\)?\s?\(?0?\)?|\(?0?\)?)7[1-57-9]\d{1}\s?\d{1}\)?\s?\d{2}\s?\d{1}\s?\d{3}$)/i;
  const errors = {};

  if (values.mobile && !mobileRegex.test(values.mobile)) {
    errors.mobile = 'Mobile number invalid. Please try again.';
  }

  return errors;
}

export {validate, simpleTextRegex, validatePassword, validateConfirmNewPassword, passwordErrors, validateMobile};
