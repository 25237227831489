import React, { useState, useEffect } from 'react';
import { useQuery, gql } from "@apollo/client";
import checkURLParams from "./check_url_params";
import YodelChevron from './yodel_chevron';

const GET_USER_PARCELS = gql`
  query {
    parcels(active: true) {
      state
      brandName
      displayState
      yodelParcel {
        upiCode
        clientName
        postcode
        state
        reasonForPreferenceNotAppliedString
        statusMessage
        statusInfo
        iconName
        nickname
        cdyParcel
        parcelType
        currentStatusId
        source
      }
      origin {
        __typename
        ... on Return {
          upiCode
          state
          brandName
          consignment
          reasonCode {
            reason
          }
          price
          labelUrl
          retailerReference
          retailerReferenceLabel
          returnReference
          returnReferenceLabel
          printInStore
          trackingTitle
          returnDeductionAmount
        }
        ... on Advice {
          upiCode
          state
          brandName
          barcodeUrl
          trackingTitle
          trackingEvents
          collectable
        }
      }
    }
  }
`;

const PwSuccesFlash = () => {
  const query = window.location.search;
  const myAccountUrlParams = new URLSearchParams(query);
  const wasPwUpdated = myAccountUrlParams.get("provenance") === "password-change";

  const [show, setShow] = useState(true);
  const isResetPwTheReferrer = document.referrer.includes("/my-details/reset_password");
  const isChangePwTheReferrer = (checkURLParams("updated") && wasPwUpdated);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false)
    }, 5000)

    return () => {
      clearTimeout(timeId)
    }
  }, []);

  if (!show || (!isResetPwTheReferrer && !isChangePwTheReferrer)) {
    return null;
  }

  if (isResetPwTheReferrer || isChangePwTheReferrer ) {
    return (
      <div className="react-flash-message -password-updated">
        {isResetPwTheReferrer ? "Password successfully updated." : null}
        {isChangePwTheReferrer ? "New password saved sucessfully." : null}
      </div>
    )
  }
}

const MyAccountLandingPage = ({ isMobile }) => {
  const { loading: userParcelsLoading, data: userParcelsData, error: userParcelsError } = useQuery(GET_USER_PARCELS);

  const showUserParcels = (data) => {
    if (data) {
      let parcelArr = [];
      let parcels = data.parcels.slice(0, 3);

      parcels.forEach((p) => {
        const { origin, yodelParcel: parcel, displayState, brandName } = p;

        if (origin?.__typename === "Return") {
          parcelArr.push(
            <div className="tracking-code-container-returns -horizontal-padding" key={origin?.upiCode}>
              <a href={"/tracking/" + origin?.upiCode} className="tracking-parcel-info-container">
                <div className="yodel-tracking-main-returns-container">
                  <div className="return-parcel-headline">
                  {
                    (parcel?.currentStatusId === 6) ? 
                      <img src={"/assets/yodel-assets/error_icon.svg"} />
                      :
                      <img src={"/assets/yodel-assets/yodel-returns-icon.svg"} />
                  }
                    
                    <h4 className="parcel-state">
                      {displayState}
                    </h4>
                  </div>
                  <div className="yodel-returns-parcel-details-container">
                    <p className="yodel-parcel-parcel-nickname">
                      {parcel?.nickname}
                    </p>
                    <p className="returns-blue-paragraph -no-margin-bottom">
                      {brandName}
                    </p>
                    <p className="returns-upi-code">
                      {origin?.upiCode}
                    </p>
                    <p className="returns-blue-paragraph">
                      {origin?.trackingTitle}
                    </p>

                    {
                      origin?.retailerReference ? (
                        <>
                          <p className="-no-margin-bottom">
                            {origin?.retailerReferenceLabel || "Retailer reference"}
                          </p>
                          <p className="returns-parcel-edd">
                            {origin?.retailerReference}
                          </p>
                        </>
                      ) : <></>
                    }

                    {
                      origin?.returnReference ? (
                        <>
                          <p className="-no-margin-bottom">
                            {origin?.returnReferenceLabel || "Return reference"}
                          </p>
                          <p className="returns-parcel-edd">
                            {origin?.returnReference}
                          </p>
                        </>
                      ) : <></>
                    }

                    {
                      origin?.reasonCode ? (
                        <>
                          <p className="-no-margin-bottom">
                            Reason
                          </p>
                          <p className="returns-parcel-edd">
                            {origin?.reasonCode?.reason}
                          </p>
                        </>
                      ) : <></>
                    }
                    
                    {
                      origin?.price !== undefined && 
                        <>
                          <p className="-no-margin-bottom">
                            Price
                          </p>
                          <p className="returns-parcel-edd">
                            {
                              (origin?.returnDeductionAmount) ? (
                                "Your retailer will deduct £" + origin?.returnDeductionAmount.toFixed(2) + " from your refund to return your parcel"
                              ) : (
                                origin?.price === 0 ?
                                  "FREE" : "£" + origin?.price
                              )
                            }
                          </p>
                        </>
                    }
                  </div>
                </div>
                <div className="parcel-chevron-icon yodel-returns-chevron">
                  <YodelChevron className="yodel-faq-button__chevron"/>
                </div>
              </a>
              {
                origin?.labelUrl &&
                  <a href={origin?.labelUrl}>
                    {origin?.printInStore ? "Open my returns barcode" : "Print my label"}
                  </a>
              }
            </div>
          )
        } else if (origin?.__typename === "Advice") {
          const parcelIcon = (parcelType) => {
            switch (parcelType) {
              case "R" : 
                return "/assets/yodel-assets/yodel-returns-icon.svg";
              case "S" : 
                return "/assets/yodel-assets/send-parcel-icon.svg";
              default:
                return "/assets/yodel-assets/advice-parcel-icon.svg";
            }
          }
            
          parcelArr.push(
            <div className="tracking-code-container-returns -horizontal-padding" key={origin?.upiCode}>
              <a href={"/tracking/" + origin?.upiCode} className="tracking-parcel-info-container">
                <div className="yodel-tracking-main-returns-container">
                  <div className="return-parcel-headline">
                  {
                    (parcel?.currentStatusId === 6) ? 
                      <img src={"/assets/yodel-assets/error_icon.svg"} />
                      :
                      <img src={parcelIcon(parcel?.parcelType) || "/assets/yodel-assets/advice-parcel-icon.svg"} />
                  }
                    <h4 className="parcel-state">
                      {displayState}
                    </h4>
                  </div>
                  <div className="yodel-returns-parcel-details-container">
                    {
                      parcel?.nickname && 
                        <p className="yodel-parcel-parcel-nickname">
                          {parcel.nickname}
                        </p>
                    }
                    <p className="returns-blue-paragraph -no-margin-bottom">
                      {origin?.brandName}
                    </p>
                    <p className="returns-upi-code">
                      {origin?.upiCode}
                    </p>
                    <p className="returns-blue-paragraph">
                      {origin?.trackingTitle}
                    </p>
                  </div>
                </div>
                <div className="parcel-chevron-icon yodel-returns-chevron">
                  <YodelChevron className="yodel-faq-button__chevron"/>
                </div>
              </a>
              {
                (origin?.collectable && parcel?.source !== "matched" && parcel?.parcelType !== "S") &&
                  <a href="/help-centre/how-do-i-get-the-collection-code-to-collect-my-deliver-to-yodel-store-parcel">
                    Open my collection code
                  </a>
              }
              {
                (origin?.barcodeUrl && origin?.collectable && parcel?.source === "matched" && parcel?.parcelType !== "S") && 
                  <a href={origin?.barcodeUrl}>
                    Open my collection code
                  </a>
              }
            </div>
          )
        } else {
          parcelArr.push(
            <a href={"/tracking/" + parcel.upiCode + (!parcel.postcode ? "" : "/" + parcel.postcode)}
              className="tracking-code-container -new-parcel-design -horizontal-padding" key={parcel.upiCode}>
              <div className="yodel-tracking-parcel-main-container">
                <div className="yodel-parcel-headline">
                  {
                    (parcel?.currentStatusId === 6) ? 
                      <img src={"/assets/yodel-assets/error_icon.svg"} /> 
                      :
                      <img src={"/assets/yodel-assets/van-icon.svg"} />
                  }
                  <h4 className="parcel-state">
                    {parcel?.statusMessage}
                  </h4> 
                </div>
                <div className="yodel-parcel-details-container">
                  {
                    parcel?.nickname && 
                      <p className="yodel-parcel-parcel-nickname">
                        {parcel.nickname}
                      </p>
                  }
                  {
                    !parcel?.nickname &&
                      <p className="yodel-parcel-client-name">
                        {parcel?.clientName}
                      </p>
                  }
                  <p className="yodel-parcel-upi-code">
                    {parcel?.upiCode}
                  </p>
                  <p className="parcel-edd">
                    {parcel?.statusInfo}
                  </p>
                  {(parcel.reasonForPreferenceNotAppliedString && (parcel.state !== "completed")) &&
                    <p className="yodel-parcel-preference">
                      {parcel.reasonForPreferenceNotAppliedString}
                    </p>
                  }
                </div>
              </div>
              <div className="yodel-parcel-chevron-icon">
                <YodelChevron className="yodel-faq-button__chevron"/>
              </div>
            </a>
          )
        }
      });

      return parcelArr;
    }
  }

  return (
    <div className="my-account-opening-section -with-header">
      <PwSuccesFlash />

      <div className="my-account-section-opener my-account-parcels-headline -horizontal-padding">
        <h3>My parcels</h3>
        
        <a href="/my-account/my-parcels" className="see-more-button">
          View all
        </a>
      </div>

      {(userParcelsLoading) ? (
        <div className="parcels-loader">
          <img src="/assets/yodel-assets/ios-spinner.svg" alt="parcel-loader-icon" />
        </div>
      ) : (userParcelsData && userParcelsData?.parcels?.length) ? (
        <>
          <div>
            {showUserParcels(userParcelsData)}
          </div>
        </>
      ) : (
        <div className="my-parcels__error -horizontal-padding">
          <img src="/assets/yodel-assets/blue-info-icon.svg" className="yodel-info-icon" />
          <p className="blue-paragraph">
            No active parcels linked to your email address or phone number
          </p>
        </div>
      )
      }
    </div>
  )
};

export default MyAccountLandingPage;
