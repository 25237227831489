import React, {useState, useEffect} from 'react';
import { Formik, Form } from 'formik';
import { gql, useMutation, useQuery } from '@apollo/client';
import ErrorBanner from '../my_account/error_banner';
import PasswordField from './password_field';
import TextField from './text_field';
import {passwordErrors, validatePassword, validateMobile} from './validation';
import {sessionTimeoutLogout} from './session_timeout_logout_helper';
import AccountDetailsHeadline from './account_details_headline';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      mobile
      userIsSsoUser
    }
  }
`;

const UPDATE_MOBILE_NUMBER = gql`
  mutation UpdateMobileNumber($mobile: String!, $password: String!) {
    updateMobileNumber(mobile: $mobile, password: $password) {
      message
    }
  }
`;

const ChangeMobileNumber  = ({isMobile}) => {
  const { loading, data: userData } = useQuery(GET_CURRENT_USER);
  const [updateMobileNumber, { data }] = useMutation(UPDATE_MOBILE_NUMBER);
  const [error, setError] = useState(null);

  const handleSubmit = (values, actions) => {
    updateMobileNumber(
      {variables: {
        mobile: values.mobile,
        password: values.password
      }}
    )
    .then(data => {
      const response = data.data?.updateMobileNumber;
      if (response.message) {
        window.location.href = '/my-details/mobile-verification?edit=true';
      } else {
        actions.setSubmitting(false);
        setError(response.errors[0]);
      }
    })
    .catch(error => {
      actions.setSubmitting(false);
      sessionTimeoutLogout(error.message);
      setError(error.message);
    });
  };

  return (
    <>
      <div className="section-content yodel-reset-password-container -narrow -flex-column">
        <AccountDetailsHeadline backLink="/my-account/manage-mobile-numbers" headline="Change mobile number" />

        <div className="yodel-reset-password-content change-mobile-info-container">
          <p className="blue-paragraph -bold">Just so you know...</p>
          <div className="bordered-container">
            <p>
              When you change your mobile number we’ll send a verification text to your new number so we know it’s you.
            </p>
          </div>
          <div className="mobile-details-info-line">
            <img src="/assets/yodel-assets/blue-info-icon.svg" className="yodel-my-account-info-icon" alt="info-icon" />

            <p className="blue-paragraph">
              Your mobile number helps ensure your parcels appear in your account and aid delivery.
            </p>
          </div>
          <p>{userData && userData.me.mobile}</p>
          {error && <ErrorBanner error={error}/>}
          <Formik
            initialValues={{
              mobile: '',
              password: ''
            }}
            onSubmit={handleSubmit}
            validate={validateMobile}
          >
            {({ values, isSubmitting }) => (
              <Form className="reset-password-form">
                <TextField
                  aria-label="New mobile number label"
                  label="New mobile number"
                  name="mobile"
                  type="tel"
                  showValidMarker={false}
                />

                {(userData && !userData?.me?.userIsSsoUser) &&
                  <PasswordField label="Password" name="password"
                    simpleValidation hideValidation={true} hidePwStrengthBar={true} />
                }

                <input
                  type="submit"
                  name="submit"
                  value="Save"
                  className="yodel-outline-button reset-password-btn"
                  disabled={isSubmitting}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ChangeMobileNumber;
