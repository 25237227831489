import React, {useState} from 'react';
import {useQuery, gql, useMutation} from "@apollo/client";
import YodelChevron from './yodel_chevron';
import { Formik, Form } from 'formik';
import {sessionTimeoutLogout} from './session_timeout_logout_helper';
import AccountDetailsHeadline from './account_details_headline';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      mobile
      userActiveMobileNumbers
      userVerifiedMobileTokens
      userUnverifiedMobileTokens
    }
  }
`;

const REMOVE_ADDITIONAL_MOBILE_NUMBER = gql`
  mutation RemoveAdditionalMobileNumber($additionalMobileNumber: String!) {
    removeAdditionalMobileNumber(additionalMobileNumber: $additionalMobileNumber) {
      message
      errors
    }
  }
`;

const ADD_ADDITIONAL_MOBILE_NUMBER = gql`
  mutation AddAdditionalMobileNumber($mobile: String!, $isResend: Boolean) {
    addAdditionalMobileNumber(mobile: $mobile, isResend: $isResend) {
      message
    }
  }
`;

const ManageMobileNumbers  = ({isMobile}) => {
  const { loading, data: userData, error } = useQuery(GET_CURRENT_USER);
  const [removeAdditionalMobileNumber, { data }] = useMutation(REMOVE_ADDITIONAL_MOBILE_NUMBER);
  const [addAdditionalMobileNumber, { data: resendData }] = useMutation(ADD_ADDITIONAL_MOBILE_NUMBER);
  const [infoIsShown, setInfoShow] = useState(false);
  const [isModalShown, setModalShow] = useState(false);
  const [removalError, setError] = useState(null);
  const [chosenMobile, setChosenMobile] = useState(null);
  const [mobileTokenThrottleError, setMobileTokenThrottleError] = useState(false);

  const userCanAddMoreMobiles = userData && userData.me.userActiveMobileNumbers && (userData.me.userActiveMobileNumbers.length < 3) ? true : false;

  const userHasMobile = userData && userData.me.mobile;

  const handleOnClick = () => {
    setInfoShow(!infoIsShown);
  }

  const openDeleteModal = (mobile) => {
    setChosenMobile(mobile);
    setModalShow(!isModalShown);
    window.scrollTo(top);
  }

  const handleSubmit = () => {
    removeAdditionalMobileNumber({
      variables: {
        additionalMobileNumber: chosenMobile,
      }
    })
    .then(data => {
      const response = data?.data?.removeAdditionalMobileNumber;
      if (response.message) {
        window.location.href = `/my-account/manage-mobile-numbers?updated=true&provenance=mobile-removed`;
      } else {
        setError(response.errors[0]);
      }
    })
    .catch(error => {
      sessionTimeoutLogout(error.message);
      setError(error.message);
      
      if (error.message.includes("status code 500")) {
        location.reload();
      }
    });
  }

  const handleResend = (mobile) => {
    addAdditionalMobileNumber({
      variables: {
        mobile: mobile,
        isResend: true
      }
    })
    .then(data => {
      const response = data?.data?.addAdditionalMobileNumber;
      if (response.message) {
        window.location.href = `/my-account/manage-mobile-numbers?updated=true&provenance=mobile-verification-resent`;
      } else {
        setError(response.errors[0]);
      }
    })
    .catch(error => {
      sessionTimeoutLogout(error.message);
      setError(error.message);

      const code = error.graphQLErrors[0]?.extensions?.code;
      if (code === "MOBILE_TOKEN_THROTTLING") {
        setMobileTokenThrottleError(true);
      }
    });
  }

  const renderUserMobiles = (userData) => {
    if (userData) {
      let mobilesArray = [];
      let verifiedMobiles = userData.me.userVerifiedMobileTokens;
      let unverifiedMobiles = userData.me.userUnverifiedMobileTokens;

      verifiedMobiles.forEach(mobile => {
        mobilesArray.push(
          <div className="section-content yodel-container yodel-preferences-container -narrow -desktop-full-width" key={mobile}>
            <div className="delivery-preferences-section__title -manage-email">
              <div>
                {mobile}
              </div>
              <span onClick={() => openDeleteModal(mobile)} className="preferences__edit-link edit-user-name-link remove-email-link">
                Remove
              </span>
            </div>
            <div className="delivery-preferences-section__cta">
              <span onClick={() => openDeleteModal(mobile)} className="preferences__edit-link hidden-on-mobile edit-user-name-link remove-email-link">
                Remove
              </span>
            </div>
          </div>
        )
      });

      unverifiedMobiles.forEach(mobile => {
        mobilesArray.push(
          <div className="section-content yodel-container yodel-preferences-container -narrow -desktop-full-width" key={mobile}>
            <div className="delivery-preferences-section__title -manage-email">
              <div className="manage-email-flex-column">
                <div>
                  {mobile}
                </div>
                <div className="error-line">
                  Mobile number has not yet been verified.
                  
                  {
                    mobileTokenThrottleError &&
                      <p>Maximum number of attempts reached, please try again later.</p>
                  }
                </div>
                <span onClick={() => handleResend(mobile)} className="yodel-outline-button -resend-verification-email">
                  Resend verification text
                </span>
              </div>
              <span onClick={() => openDeleteModal(mobile)} className="preferences__edit-link edit-user-name-link remove-email-link">
                Remove
              </span>
            </div>
            <div className="delivery-preferences-section__cta">
              <span onClick={() => openDeleteModal(mobile)} className="preferences__edit-link hidden-on-mobile edit-user-name-link remove-email-link">
                Remove
              </span>
            </div>
          </div>
        )
      });

      return mobilesArray;
    }
  }

  const MobileDeleteModal = ({mobile}) => {
    return(
      <div className="email-modal-container">
        <div className="email-delete-modal">
          <h3>
            Are you sure?
          </h3>
          <div className="confirmation-container">
            <p>
              Would you like to remove this mobile number?
            </p>
            <p>{mobile}</p>
          </div>
          <div className="buttons-container">
            <button onClick={() => setModalShow(false)}
              className="yodel-outline-button -secondary-style">
              No
            </button>
            <button type="submit" className="yodel-outline-button" onClick={handleSubmit}>
              Yes
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section className="my-account-layout-section manage-additional-emails-section">
      <AccountDetailsHeadline backLink="/my-account/account-details"  headline="Mobile numbers" />
      <div className="section-content yodel-container yodel-preferences-container -narrow -desktop-full-width">
        {
          userHasMobile ?
          (
            <>
              <div className="delivery-preferences-section__title -manage-email">
                <div className="preferences-content__label -full-width">
                  {userData && userData.me.mobile}
                </div>
                <a href="/my-account/change-mobile-number" className="preferences__edit-link edit-user-name-link">
                  Edit
                </a>
              </div>
              <div className="delivery-preferences-section__cta">
                <a href="/my-account/change-mobile-number" className="preferences__edit-link hidden-on-mobile edit-user-name-link">
                  Edit
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="delivery-preferences-section__title -manage-email">
                <div className="preferences-content__label -full-width">
                  Add a mobile number
                </div>
                <a href="/my-details" className="preferences__edit-link edit-user-name-link">
                  Add
                </a>
              </div>
              <div className="delivery-preferences-section__cta">
                <a href="/my-details" className="preferences__edit-link hidden-on-mobile edit-user-name-link">
                  Add
                </a>
              </div>
            </>
          )
        }

      </div>
      {userHasMobile &&
        <>
          {userData && renderUserMobiles(userData)}

          <div className="section-content yodel-container yodel-preferences-container -narrow -desktop-full-width -column-layout">
            {userCanAddMoreMobiles &&
              <a href="/my-account/add-additional-mobile-number" className="yodel-manage-email-additional-info-container -link">
                <div className="wider-row">
                  Add another mobile number
                </div>
                <div className="thin-row">
                  <YodelChevron/>
                </div>
              </a>
            }
            <div className={`yodel-manage-email-additional-info-container ${userCanAddMoreMobiles ? "-top-border" : ""}`}>
              <div className="wider-row">
                What adding an additional mobile number means.
              </div>
              <div className="thin-row" onClick={handleOnClick}>
                <img src={'/assets/yodel-assets/cross-clear-input.svg'}
                  className={`yodel-plus-icon ${infoIsShown ? "-rotated" : null}`}
                  alt="plus-information-icon"/>
              </div>
            </div>
            <div className={`yodel-manage-email__opening-section ${infoIsShown ? "-shown" : "-hidden"}`}>
              <p>
                Adding another mobile number means we will use it to find parcels linked to that mobile number and display them on your homepage.
              </p>
              <p>
                You can add a maximum of 3 additional mobile numbers.
              </p>
              <p>
                You cannot sign in to your Yodel account with any additional mobile numbers you add.
              </p>
            </div>
          </div>
        </>
      }

      {isModalShown && <MobileDeleteModal mobile={chosenMobile} />}
    </section>
  );
};

export default ManageMobileNumbers;
