import React, { useState, useEffect, useRef } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import YodelChevron from './yodel_chevron';

const YodelDropdown = (props) => {
  const {
    wrapperProps = {},
    options,
    name,
    labelField,
    labelFieldExtra,
    valueField,
    unselectedLabel,
    isMobile,
    customClass = false,
    clearInput = false,
    onClearInput = null,
    onChange = null,
    throttleError = false,
    ...extraProps
  } = props;

  const nodeRef = useRef(null)
  const [field, meta, helpers] = useField(name);
  const { setValue, setTouched } = helpers;
  const { value } = meta;
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [divActive, setDivActive] = useState(false)
  const handleMouseDown = (e) => {
    //  Mouse down inside div
    if (nodeRef.current && nodeRef.current.contains(e.target)) {
      return;
    }
    // Mouse down outside of div
    setDropdownOpen(false)
    setDivActive(false)
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [])

  const handleOptionClick = (option) => {
    setValue(option);
    setTouched(true);
    setDropdownOpen(false);
    onChange && onChange(option);
  }

  const optionLabel = (option) => {
    if (optionValue(option) === "not_listed") {
      return "Address not listed"
    } else if (option[labelField] && option[labelFieldExtra]) {
      return option[labelField] + ", " + option[labelFieldExtra];
    } else if (labelField) {
      return option[labelField]
    } else {
      return option
    }
  }

  const optionValue = (option) => {
    return valueField ? option[valueField] : option;
  }

  const handleSelectChange = (value) => {
    const selectedValue = (options.find(option => optionValue(option) === value) || null);
    setValue(selectedValue);
    onChange && onChange(selectedValue);
  };

  const clearField = () => {
    setValue(null);
    setDropdownOpen(false);
    onClearInput && onClearInput();
  }

  const renderOptions = () => (
    <div className="dropdown-options-wrapper">
      {options && options.map((option, index) => (
        <div
          role="option"
          className="dropdown-option"
          key={index + 1}
          onClick={() => handleOptionClick(option)}
        >
          {optionLabel(option)}
        </div>
        ))
      }
    </div>
  )

  return (
    isMobile ? (
      <div className={`yodel-select-and-clear-button-container ${customClass ? '-red-border' : null} ${(value && clearInput) ? "-option-selected" : ""}`}>
        <select
          name={name}
          value={value ? optionValue(value) : "unselected"}
          onChange={event => handleSelectChange(event.target.value)}
          // onBlur={() => setFieldTouched(name)}
          className="yodel-select"
          {...extraProps}
        >
          <option value="unselected" key="unselected">{unselectedLabel}</option>
          {options && options.map(option =>
            <option value={optionValue(option)} key={optionValue(option)}>
              {optionLabel(option)}
            </option>
          )}
        </select>
        {(value && clearInput) &&
          <span className="clear-yodel-dropdown-button"
            onClick={(e) => {
              clearField();
              e.stopPropagation();
            }}>
            <img src={'/assets/yodel-assets/cross-clear-input.svg'} alt="clear-input-icon"/>
          </span>
        }
      </div>
    ) : (
      <div
        className={`dropdown-wrapper ${!divActive && !dropdownOpen ? '' : 'active'}`}
        {...wrapperProps}
        ref={nodeRef}
      >
        <div
          role="listbox"
          className={`dropdown-toggle-button ${customClass ? '-red-border' : null} ${!divActive && !dropdownOpen ? '' : 'active'} ${value ? '' : 'placeholder'} ${throttleError ? '-red-border' : null}`}
          onClick={() => {
            setDivActive(true);
            setDropdownOpen(!dropdownOpen)
          }}
          {...extraProps}
        >
          <span>
            {value ? optionLabel(value) : unselectedLabel}
          </span>
          {
            (value && clearInput) ?
              <span className="clear-yodel-dropdown-button"
                onClick={(e) => {
                  clearField();
                  e.stopPropagation();
                }}>
                <img src={'/assets/yodel-assets/cross-clear-input.svg'} alt="clear-input-icon"/>
              </span>
              :
              <YodelChevron/>
          }
        </div>
        {dropdownOpen && renderOptions()}
      </div>
    )
  );
}

YodelDropdown.propTypes = {
    // Array of selectable objects
    options: PropTypes.array,
    // The name of the form field
    name: PropTypes.string.isRequired,
    // Placeholder text inside the control pre-selection
    unselectedLabel: PropTypes.string.isRequired,
    // Function to format option / value (defaults to just returning the option value)
    labelField: PropTypes.string,
    labelFieldExtra: PropTypes.string,
    valueField: PropTypes.string,
    // Any additional attributes we want to set on the wrapper div can be passed here
    wrapperProps: PropTypes.object
};

export default YodelDropdown;
