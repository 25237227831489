import React, {useState, useEffect} from 'react';

const VerificationSection = ({userEmail, state}) => {
  return (
    <>
      <p className="blue-paragraph">
        {
          (state === "account-inactive") ?
            "Your account is not active"
            :
            "Verify your email address"
        }
      </p>

      <div className="bordered-container">
        <p>A verification email has been sent to: <strong>{userEmail}</strong> </p>

        <p>Simply click on the link in the email to finish setting up your account. </p>
      </div>

      <div className="expiry-line">
        <img src="/assets/yodel-assets/timer-icon.svg" alt="timer-icon" />
        <p>The link in the email will expire after <strong>30 minutes.</strong></p>
      </div>
    </>
  );
}

const NotReceivedEmailSection = ({userId}) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]') &&
   document.querySelector('meta[name="csrf-token"]').getAttribute("content");

  return (
    <>
      <p className="blue-paragraph">Not received the email?</p>
      <ul className="email-verification-list -forgotten-password">
        <li>Please check your junk folder </li>
        <li>
          <form className="resend-verification-form" action={`/users/${userId}/activation`}  method="post" acceptCharset="UTF-8">
            <input type="hidden" name="authenticity_token" value={csrfToken || ''} />
            <input
              type="submit"
              name="submit"
              value="Resend verification email"
              className="resend-verification-link"
            />
          </form>
        </li>
      </ul>
    </>
  );
}

const EmailVerificationPage  = ({isMobile, userEmail, userId, provenance}) => {
  useEffect(() => {
    if (provenance !== 'login') {
      if (isMobile) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        window.scroll({ top: 310, behavior: 'smooth' });
      }
    }
  });

  return (
    isMobile ? (
      <div className="signup-content -email-verification">
        {provenance === 'login'
          ? <VerificationSection state="account-inactive" userEmail={userEmail} />
          : <VerificationSection userEmail={userEmail} />
        }
        <NotReceivedEmailSection userId={userId} />
      </div>
    ) : (
      <>
        <div className="-with-border signup-content">
          {provenance === 'login' ?
            <VerificationSection state="account-inactive" userEmail={userEmail} userId={userId} />
            :
            <VerificationSection userEmail={userEmail} userId={userId} />
          }
          <NotReceivedEmailSection userId={userId} />
        </div>

        <div className="signup-content">
        </div>
      </>
    )
  );
};

export default EmailVerificationPage;
