import React, {useState, useEffect} from 'react';
import {useQuery, gql} from "@apollo/client";

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      requestedNewEmailAddress
    }
  }
`;

const EmailChangeVerificationPage = () => {
  const { data } = useQuery(GET_CURRENT_USER);

  return (
    <div className="section-content yodel-container -narrow -flex-column -negative-margin">
        <h3 className="blue-paragraph">
          Verify your email address
        </h3>

        <div className="bordered-container">
          <p>
            A verification email has been sent to: <strong>{data && data.me.requestedNewEmailAddress}</strong>
          </p>

          <p>Simply click on the link in the email to finish setting up your account. </p>
        </div>

        <div className="expiry-line">
          <img src="/assets/yodel-assets/timer-icon.svg" alt="timer-icon" />
          <p>The link in the email will expire after <strong>30 minutes.</strong></p>
        </div>

        <h3 className="blue-paragraph">Not received the email?</h3>
        <ul className="email-verification-list -forgotten-password">
          <li>Please check your junk folder </li>
          <li>
            <a href="/my-account/change-email-address">Resend verification email</a>
          </li>
        </ul>

    </div>
  );
}

export default EmailChangeVerificationPage;
