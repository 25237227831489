import React, {useState, useEffect} from 'react';

const isMobileWindow = () => {
  return window.innerWidth < 960;
}

const TwoTabsLayout = ({leftTab, rightTab, activeTab}) => {
  const [isMobile, setIsMobile] = useState(isMobileWindow());
  const [selectedTab, setSelectedTab] = useState(activeTab);

  const LoginButton = ({selectedTab, onClick}) => {
    return (
      <div className={`login-header ${selectedTab === 'login' ? '-active' : ''}`}
        data-login-button
        onClick={onClick}>
        <h2>Log in</h2>
      </div>
    )
  }

  const SignUpButton = ({selectedTab, onClick}) => {
    return (
      <div className={`signup-header ${selectedTab === 'signup' ? '-active' : ''}`}
        data-signup-button
        onClick={onClick}>
        <h2>Sign up</h2>
      </div>
    )
  }

  const handleResize = () => {
    setIsMobile(isMobileWindow());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return(
    <section className={`${isMobile ? "-mobile" : "hidden-on-mobile"} yodel-registration-section`}>
      <div className="section-content yodel-registration-content">
        <div className="login-container">
          <div className="header-container">
            <LoginButton
              selectedTab={selectedTab}
              onClick={() => setSelectedTab('login')}
            />
            <SignUpButton
              selectedTab={selectedTab}
              onClick={() => setSelectedTab('signup')}
            />
          </div>

          <div className="login-content-container" data-login-active-content
            style={{display: selectedTab === 'login' ? 'flex' : 'none'}}>
            {React.cloneElement(leftTab, { isMobile: isMobile })}
          </div>
          <div className="signup-content-container" data-signup-active-content
            style={{display: selectedTab === 'signup' ? 'flex' : 'none'}}>
            {React.cloneElement(rightTab, { isMobile: isMobile })}
          </div>
        </div>
      </div>
    </section>
  )
};

export default TwoTabsLayout;
