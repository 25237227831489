import React, {useState, useEffect} from 'react';
import LoginForm from '../my_account/login_form';
import SocialSSO from '../my_account/social_sso';
import checkURLParams from "./check_url_params";

import ErrorBanner from '../my_account/error_banner';

const SuccesFlash = () => {
  const query = window.location.search;
  const myAccountUrlParams = new URLSearchParams(query);
  const emailChangeParamPresent = myAccountUrlParams.get("provenance") === "email-change-request-resent";

  const [show, setShow] = useState(true);
  const isEmailChangeTheReferrer = document.referrer.includes("/my-account/email-change-request-expired");
  const emailChangeHappened = (checkURLParams("updated") && emailChangeParamPresent);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false)
    }, 5000)

    return () => {
      clearTimeout(timeId)
    }
  }, []);

  if (!show || (!emailChangeHappened || !isEmailChangeTheReferrer)) {
    return null;
  }

  if (emailChangeHappened && isEmailChangeTheReferrer) {
    return (
      <div className="react-flash-message -verification-sent">
        Verification email is sent.
      </div>
    )
  }
}

const LoginPage = ({isMobile, errorMessage}) => {
  return (
    <>
      <SuccesFlash />
      <div className={(isMobile ? "" : "-with-border") + " signup-content"} >
        <p>Log in to manage your Yodel deliveries.</p>

        {isMobile && (
          <>
            <SocialSSO isMobile={true} isSignUp={false} />
            <div className="login-options-separator">
              <span>or</span>
            </div>
          </>
        )}

        {errorMessage && <ErrorBanner error={errorMessage}/>}
        <LoginForm />
      </div>
      {!isMobile && <SocialSSO isSignUp={false} />}
    </>
  )
};

export default LoginPage;
