import React, {useState, useEffect} from 'react';
import { Formik, Form } from 'formik';
import { gql, useMutation, useQuery } from '@apollo/client';
import ErrorBanner from '../my_account/error_banner';
import PasswordField from './password_field';
import TextField from './text_field';
import {passwordErrors, validatePassword} from './validation';
import {sessionTimeoutLogout} from './session_timeout_logout_helper';
import AccountDetailsHeadline from './account_details_headline';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      id
      email
      userIsSsoUser
    }
  }
`;

const CHANGE_USER_EMAIL = gql`
  mutation ChangeEmail($newEmail: String!, $password: String!) {
    changeEmail(newEmail: $newEmail, password: $password) {
      message
      errors
    }
  }
`;

const ChangeEmailAddress  = ({isMobile}) => {
  const { loading, data: userData } = useQuery(GET_CURRENT_USER);
  const [changeEmail, { data }] = useMutation(CHANGE_USER_EMAIL);
  const [error, setError] = useState(null);

  const handleSubmit = (values, actions) => {
    changeEmail(
      {variables: {
        newEmail: values.newEmail,
        password: values.password,
      }}
    )
    .then(data => {
      const response = data.data?.changeEmail;
      if (response.message) {
        window.location.href = `/my-account/verify-email-change`;
      } else {
        actions.setSubmitting(false);
        setError(response.errors[0]);
      }
    })
    .catch(error => {
      actions.setSubmitting(false);
      sessionTimeoutLogout(error.message);
      setError(error.message);
    });
  };

  return (
    <>
      <div className="section-content yodel-reset-password-container -narrow -flex-column">
        <AccountDetailsHeadline backLink="/my-account/manage-email-addresses" headline="Change email address" />

        <div className="yodel-reset-password-content">
          <p className="blue-paragraph -bold">Just so you know...</p>
          <div className="bordered-container">
            {
              userData && userData.me.userIsSsoUser ?
              (
                <>
                  <p>
                    Changing your email address means you will no longer be able to log in via social media.
                  </p>
                  <p>
                    If you wish to continue, type in your new email address below and we will send a link to request verification.
                  </p>
                </>
              ) : (
                <p>
                  Changing your email address means we’ll send a verification link to your new email address so we know it’s you.
                </p>
              )
            }
          </div>
          <p>{userData && userData.me.email}</p>
          {error && <ErrorBanner error={error}/>}
          <Formik
            initialValues={{
              newEmail: '',
              password: '',
            }}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting }) => (
              <Form className="reset-password-form">
                <TextField
                  aria-label="New email address label"
                  label="New email address" name="newEmail"
                  type="text"
                  showValidMarker={false} />
                {(userData && !userData.me.userIsSsoUser) &&
                  <PasswordField label="Password" name="password"
                    simpleValidation hideValidation={true} hidePwStrengthBar={true} />
                }
                <input
                  type="submit"
                  name="submit"
                  value="Save"
                  className="yodel-outline-button reset-password-btn"
                  disabled={isSubmitting}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ChangeEmailAddress;
