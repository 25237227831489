import React, {useState, useEffect} from 'react';
import {useQuery, useMutation, gql} from "@apollo/client";
import {useParams} from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import ErrorBanner from '../my_account/error_banner';
import {sessionTimeoutLogout} from './session_timeout_logout_helper';
import {simpleTextRegex} from './validation'

const GET_USER_ADDRESS = gql`
  query GetUserAddress($guid: String!) {
    userAddress(guid: $guid) {
      propertyNotes
    }
  }
`;

const CAPTURE_PROPERTY_NOTES = gql`
  mutation CapturePropertyNotes($userAddressGuid: String!, $propertyNotes: String!) {
    capturePropertyNotes(userAddressGuid: $userAddressGuid, propertyNotes: $propertyNotes) {
      userAddressGuid
      errors
    }
  }
`;

const PropertyNotes = ({isMobile}) => {
  const { guid } = useParams();
  const { loading, data, error: addressError } =
  useQuery(GET_USER_ADDRESS, {variables: {guid: guid}});
  const [propertyNotesError, setPropertyNotesError] = useState(null);
  const [capturePropertyNotes] = useMutation(CAPTURE_PROPERTY_NOTES);

  const characterCount = (values) => values.propertyNotes?.length || 0;

  const provenance = document.querySelector("[data-provenance]").dataset.provenance;

  const arePropertyNotesValid = ({ propertyNotes }) => {
    if (!simpleTextRegex.test(propertyNotes)) {
      setTimeout(() => setPropertyNotesError("Describe your special instructions using only valid characters including letters, numbers, spaces and - , . * #, excluding line breaks"), 0);
      return false;
    } else {
      setTimeout(() => setPropertyNotesError(null), 0);
      return true;
    }
  }

  const handleSubmit = (values, actions) => {
    capturePropertyNotes(
      {variables: {
        userAddressGuid: guid,
        propertyNotes: values.propertyNotes,
      }}
    )
    .then(data => {
      const response = data.data && data.data.capturePropertyNotes;
      if (response.userAddressGuid) {
        switch (provenance) {
          case "review" :
            window.location.href = `/my-details/${guid}/review?updated=true&provenance=property-notes`
            break;
          case "preferences" :
            window.location.href = `/my-account/delivery/${guid}?updated=true&provenance=property-notes`;
            break;
          case "delivery-preferences" :
            window.location.href = `/my-details/${guid}/review?updated=true`
            break;
            case "additional_delivery_address" :
              window.location.href = `/my-details/${guid}/review?updated=true&provenance=additional_delivery_address` //question
              break;
        }
      } else {
        actions.setSubmitting(false);
        setPropertyNotesError(response.errors[0]);
      }
    })
    .catch(error => {
      actions.setSubmitting(false);
      sessionTimeoutLogout(error.message);
      setPropertyNotesError(error.message);
    });
  };

  const propertyNotes = () => {
    if (data?.userAddress?.propertyNotes) {
      return data?.userAddress?.propertyNotes;
    } else {
      return '';
    }
  }

const AccountDetailsHeadline = () => {
  return(
    <div className="section-content yodel-container -narrow -negative-margin -head-section-content -with-header-actions -desktop-full-width">
      <a href={`${provenance == "review" ? `/my-details/${guid}/review` : provenance == "preferences" ? `/my-account/delivery/${guid}` : "/my-account/delivery-addresses"}`} className="yodel-container__header-action-container">
        <img src="/assets/yodel-assets/header-back-arrow.svg"
          className="yodel-header-back-arrow" alt="back-arrow-icon" />
      </a>
      <h2 className="yodel-container__header-title">Special instructions</h2>
      <div className="yodel-container__header-action-container"></div>
    </div>
  )
}

  return (
    <div className="section-content yodel-reset-password-container -narrow -flex-column">
      <AccountDetailsHeadline/ >

      <div className="yodel-reset-password-content">
        <Formik
        initialValues={{
          propertyNotes: propertyNotes(),
        }}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, errors }) => (
            <Form className="new_user my-details-safeplace-form" noValidate>
              <p>
                Do you have any additional property information to help us deliver your parcels?
              </p>

              <p> For example: </p>

              <p className="safeplace-line">
                <span className="safeplace-step-icon"></span>
                An access code
              </p>
              <p className="safeplace-line">
                <span className="safeplace-step-icon"></span>
                Extra time to answer your door
              </p>
              <p className="safeplace-line">
                <span className="safeplace-step-icon"></span>
                Additional directions
              </p>

              {propertyNotesError && <ErrorBanner error={propertyNotesError} />}

              <Field
                name="propertyNotes"
                rows="3"
                placeholder="Please type..."
                as="textarea"
                className="safe-place-instructions-input"
                maxLength="100"
              />

              <span className="safe-place-character-count">
                {characterCount(values)}/100 characters
              </span>

              <p className="what-3-words-copy">
                If you enter your what3words here, our drivers won't see them. To add what3words to your address, download the Yodel app for iOS or Android
              </p>

              <input
                type="submit"
                name="submit"
                value="Save"
                className="yodel-outline-button reset-password-btn"
                disabled={isSubmitting || loading || (values.propertyNotes && !arePropertyNotesValid(values))}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
};

export default PropertyNotes;
