import React, {useState, useEffect} from 'react';
import {useQuery, useLazyQuery, useMutation, gql} from "@apollo/client";
import {useParams} from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import TextField from './text_field';
import YodelDropdown from './yodel_dropdown';
import ErrorBanner from '../my_account/error_banner';
import YodelChevron from '../my_account/yodel_chevron';
import {sessionTimeoutLogout} from './session_timeout_logout_helper';

const GET_USER_ADDRESS = gql`
  query GetUserAddress($guid: String!) {
    userAddress(guid: $guid) {
      preferredNeighbourAddress {
        uprn
        postcode
        addressLine1
        addressLine2
        town
        county
        latitude
        longitude
      }
      unpreferredNeighbourAddress {
        uprn
        postcode
        addressLine1
        addressLine2
        town
        county
        latitude
        longitude
      }
      addresses(onlyNeighbours: true) {
        uprn
        postcode
        addressLine1
        addressLine2
        town
        county
        latitude
        longitude
      }
    }
  }
`;

const CREATE_NEIGHBOUR_PREFERENCES = gql`
  mutation CreateNeighbourPreferences($guid: String!,
    $preferred: AddressAttributes, $unpreferred: AddressAttributes
  ) {
    createNeighbourPreferences(userAddressGuid: $guid,
      preferred: $preferred, unpreferred: $unpreferred
    ) {
      userAddressGuid
      errors
    }
  }
`;

const MyDetailsNeighbourPage = ({isMobile}) => {
  const [neighbourAddressError, setNeighbourAddressError] = useState(null);
  const { guid } = useParams();
  const { loading, data, error: addressError } =
    useQuery(GET_USER_ADDRESS, {variables: {guid: guid}});
  const [createNeighbourPreferences] = useMutation(CREATE_NEIGHBOUR_PREFERENCES);
  const editMode = document.querySelector("[data-edit-mode]").dataset.editMode;
  const provenance = document.querySelector("[data-provenance]").dataset.provenance;

  const neighbourAddresses = (excluding) => {
    if (data && data.userAddress.addresses) {
      if (excluding) {
        return data.userAddress.addresses.filter(address =>
          address.uprn !== excluding.uprn)
      } else {
        return data.userAddress.addresses;
      }
    } else {
      return null;
    }
  };

  const sanitizeAddress = (address) => {
    if (address) {
      const {__typename, ...sanitizeAddress} =  address;
      return sanitizeAddress;
    } else {
      return null;
    }
  }

  const handleSubmit = (values, actions) => {
    const preferredNeighbour = sanitizeAddress(values.preferredNeighbour);
    const unpreferredNeighbour = sanitizeAddress(values.unpreferredNeighbour);

    createNeighbourPreferences(
      {variables: {
        guid: guid, preferred: preferredNeighbour,
        unpreferred: unpreferredNeighbour
      }}
    )
    .then(data => {
      const response = data.data && data.data.createNeighbourPreferences;
      if (response.userAddressGuid) {
        if (editMode) {
          switch (provenance) {
            case "review" :
              window.location.href = `/my-details/${guid}/review?updated=true&provenance=neighbour`
              break;
            case "preferences" :
              window.location.href = `/my-account/delivery/${guid}?updated=true&provenance=neighbour`
              break;
            case "delivery-preferences" :
              window.location.href = `/addresses/${guid}/safe-place?edit=true&provenance=delivery-preferences`
              break;
            case "additional_delivery_address" :
              window.location.href = `/addresses/${guid}/safe-place?edit=true&provenance=additional_delivery_address`
              break;
          }
        } else {
          window.location.href = `/addresses/${guid}/safe-place`;
        }
      } else {
        actions.setSubmitting(false);
        setNeighbourAddressError(response.errors[0]);
      }
    })
    .catch(error => {
      actions.setSubmitting(false);
      sessionTimeoutLogout(error.message);
      setNeighbourAddressError(error.message);
      //scrollToErrorBanner();
    });
  };

  return (
    <Formik
      initialValues={{
        preferredNeighbour: data?.userAddress?.preferredNeighbourAddress,
        unpreferredNeighbour: data?.userAddress?.unpreferredNeighbourAddress
      }}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, errors, isValid }) => (
        <Form className="new_user my-details-neighbour-form" noValidate>
          {!editMode &&
            <h2>Neighbour</h2>
          }
          <p className="my-details-neighbour-form__subtitle">Tell us if there's a neighbour we can deliver to if no-one is in.</p>
          {(neighbourAddressError || addressError) &&
              <ErrorBanner error={neighbourAddressError || addressError.message}/>}

          <p>Preferred neighbour</p>

          <div className="yodel-form-element-container">
            <YodelDropdown
              options={neighbourAddresses(values.unpreferredNeighbour)}
              labelField="addressLine1"
              labelFieldExtra="addressLine2"
              valueField="uprn"
              clearInput={true}
              id="my-details-preferred-neighbour"
              name="preferredNeighbour"
              unselectedLabel="Please select"
              isMobile={isMobile}
            />
          </div>

          <p>Avoid neighbour (if applicable)</p>

            <div className="yodel-form-element-container">
              <YodelDropdown
                options={neighbourAddresses(values.preferredNeighbour)}
                labelField="addressLine1"
                labelFieldExtra="addressLine2"
                valueField="uprn"
                clearInput={true}
                id="my-details-unpreferred-neighbour"
                name="unpreferredNeighbour"
                unselectedLabel="Please select"
                isMobile={isMobile}
              />
            </div>
          {editMode ?
            <button type="submit" name="submit"
              className="yodel-faq-button register-signup-btn -end-of-block-bottom-margin">
              Save
            </button>
          :
            <button type="submit" name="submit"
              className={(values.preferredNeighbour || values.unpreferredNeighbour ? "" : "-secondary-style") + " yodel-faq-button register-signup-btn -with-chevron -centered-chevron -end-of-block-bottom-margin"}
              disabled={isSubmitting || loading}>
              {(values.preferredNeighbour || values.unpreferredNeighbour) ? "Next" : "Skip"}
              <YodelChevron className="yodel-faq-button__chevron"></YodelChevron>
            </button>
          }
        </Form>
      )}
    </Formik>
  )
};

export default MyDetailsNeighbourPage;
