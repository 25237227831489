import React, {useState, useEffect} from 'react';
import { Formik, Form } from 'formik';
import { gql, useMutation, useQuery } from '@apollo/client';
import ErrorBanner from '../my_account/error_banner';
import PasswordField from './password_field';
import TextField from './text_field';
import {passwordErrors, validatePassword} from './validation';
import {sessionTimeoutLogout} from './session_timeout_logout_helper';
import {validate} from './validation';
import AccountDetailsHeadline from './account_details_headline';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    me {
      id
    }
  }
`;

const ADD_ADDITIONAL_MOBILE_NUMBER = gql`
  mutation AddAdditionalMobileNumber($mobile: String!) {
    addAdditionalMobileNumber(mobile: $mobile) {
      message
    }
  }
`;

const AddAdditionalMobileNumber  = ({isMobile}) => {
  const { loading, data: userData } = useQuery(GET_CURRENT_USER);
  const [addAdditionalMobileNumber, { data }] = useMutation(ADD_ADDITIONAL_MOBILE_NUMBER);
  const [error, setError] = useState(null);
  const [mobileTokenThrottleError, setMobileTokenThrottleError] = useState(false);

  const handleSubmit = (values, actions) => {
    addAdditionalMobileNumber(
      {variables: {
        mobile: values.additionalMobile,
      }}
    )
    .then(data => {
      const response = data.data?.addAdditionalMobileNumber;
      if (response.message) {
        window.location.href = `/my-account/account-details?updated=true&provenance=mobile-added`;
      } else {
        actions.setSubmitting(false);
        setError(response.errors[0]);
      }
    })
    .catch(error => {
      actions.setSubmitting(false);
      sessionTimeoutLogout(error.message);
      setError(error.message);
      
      const code = error.graphQLErrors[0]?.extensions?.code;
      if (code === "MOBILE_TOKEN_THROTTLING") {
        setMobileTokenThrottleError(true);
      }
    });
  };

  return (
    <>
      <div className="section-content yodel-reset-password-container -narrow -flex-column">
        <AccountDetailsHeadline backLink="/my-account/manage-mobile-numbers" headline="Add a mobile number" />

        <div className="yodel-reset-password-content">
          <p className="blue-paragraph -bold">Just so you know...</p>
          <div className="bordered-container">
            <p>
              Adding another mobile number means we’ll send a verification link to that number.
            </p>
          </div>
          {error && <ErrorBanner error={error}/>}
          <Formik
            initialValues={{
              additionalMobile: '',
            }}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting }) => (
              <Form className="reset-password-form">
                <div className="mobile-details-container">
                  <TextField
                    aria-label="Mobile number input"
                    label="Mobile number"
                    name="additionalMobile"
                    type="text"
                    showValidMarker={false}
                    errorMessage={mobileTokenThrottleError && 'Maximum number of attempts reached, please try again later.'}
                    throttleError={mobileTokenThrottleError}
                  />
                </div>

                <input
                  type="submit"
                  name="submit"
                  value="Save"
                  className="yodel-outline-button reset-password-btn"
                  disabled={isSubmitting}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddAdditionalMobileNumber;
