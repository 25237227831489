import React from 'react';

const checkURLParams = (param) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);

  return urlParams.get(param) === "true";
}

export default checkURLParams;
