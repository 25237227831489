import React from 'react';

const AccountDetailsHeadline = ({backLink, headline}) => {
  return(
    <div className="section-content yodel-container -narrow -negative-margin -head-section-content -with-header-actions -desktop-full-width">
      <a href={backLink} className="yodel-container__header-action-container">
        <img src="/assets/yodel-assets/header-back-arrow.svg"
          className="yodel-header-back-arrow" alt="back-arrow-icon" />
      </a>
      <h2 className="yodel-container__header-title">{headline}</h2>
      <div className="yodel-container__header-action-container"></div>
    </div>
  )
}

export default AccountDetailsHeadline;
