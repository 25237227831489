import React from 'react';

const YodelChevron = ({className = ""}) => {
  return(
    <svg className={`${className}`} xmlns="http://www.w3.org/2000/svg" width="13.24" height="19.67" viewBox="0 0 13.24 19.67">
      <path id="angle-right" d="M13.24,9.849,2.282,19.67,0,17.654l8.709-7.8L0,2.045,2.282,0Z" fill="#767676"/>
    </svg>

  )
};

export default YodelChevron;
