import React, { useState, useEffect } from 'react';
import ErrorBanner from '../my_account/error_banner';

const SocialSSO = ({isMobile, isSignUp, ssoError}) => {
  const [csrfToken, setCsrfToken] = useState("");

  useEffect(() => {
    setCsrfToken(document.querySelector("#my-account").dataset.csrfToken);
  }, []);

  return(
    <div
      className={`${isMobile ? 'sso-buttons' : '-with-margin'} signup-content`}>
      {ssoError && <ErrorBanner error={ssoError} timeoutEnabled={true} />}
      <div className="sso-button-container">
        <form data-turbo='false' action='/auth/facebook' method='post'>
          <input type='hidden' name='authenticity_token' value={csrfToken} />
          <button className='sign-in-via-facebook-link' type='submit'>
            <img src="/assets/yodel-assets/facebook-white-icon.svg" alt="facebook-icon" />
            <span>{isSignUp ? "Continue with Facebook" : "Log in with Facebook"}</span>
          </button>
        </form>
      </div>
      <div className="sso-button-container">
        <form data-turbo='false' action='/auth/google_oauth2' method='post'>
          <input type='hidden' name='authenticity_token' value={csrfToken} />
          <button className='sign-in-via-google-link' type='submit'>
            <img src="/assets/yodel-assets/google-icon.svg" alt="google-icon" />
            <span>{isSignUp ? "Continue with Google" : "Log in with Google"}</span>
          </button>
        </form>
      </div>
    </div>
  )
};

export default SocialSSO;
