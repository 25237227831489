import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import checkURLParams from "./check_url_params";

const isMobileWindow = () => {
  return window.innerWidth < 960;
}

const SetupStepIndicator = ({state}) => {
  const isDeliveryPrefsTheReferrer = checkURLParams("edit");
  const provenance = document.querySelector("[data-provenance]").dataset.provenance;

  return(
    <div className="section-content yodel-container -negative-margin -narrow account-setup-header">
      <div className="setup-step-indicator-container">
        <div className="setup-header-headline-container">
          <a href={
              (provenance === "additional_delivery_address") ?
                "/my-account/delivery-addresses" : "/my-account"}>
            <img className="yodel-header-back-arrow" src="/assets/yodel-assets/header-back-arrow.svg" alt="back-icon" />
          </a>
          <h2>
            {!isDeliveryPrefsTheReferrer ? "Set up your account" :
              (provenance === "additional_delivery_address") ? "Delivery preferences" :
                "Edit your delivery preferences"}
          </h2>
        </div>
        <div className="steps-container">
          <div className="setup-connection-line"></div>
          <div className="steps">
            {
              (state === "my-details") ? (
                <div className="rounded-borders -active-step">
                  <img src="/assets/yodel-assets/address-icon.svg" alt="address-icon" />
                </div>
              ) : (
                <div className="rounded-borders -completed-step">
                </div>
              )
            }

            My Details
          </div>
          <div className="steps">
            {
              (state === "neighbour") ? (
                <div className="rounded-borders -active-step">
                  <img src="/assets/yodel-assets/neighbour-icon.svg" alt="neighbour-icon" />
                </div>
              ) :
              (state === "safePlace") ? (
                <div className="rounded-borders -completed-step">
                </div>
              ) : (
                <div className="rounded-borders">
                  <img src="/assets/yodel-assets/neighbour-icon.svg" alt="neighbour-icon" />
                </div>
              )
            }

            Neighbour
          </div>
          <div className="steps">
            {
              (state === "safePlace") ? (
                <div className="rounded-borders -active-step">
                  <img src="/assets/yodel-assets/safe-place.svg" className="safe-place-icon" alt="safe-place" />
                </div>
              ) : (
                <div className="rounded-borders">
                  <img src="/assets/yodel-assets/safe-place.svg" alt="safe-place" />
                </div>
              )
            }

            Safe Place
          </div>
        </div>
      </div>
    </div>
  )
}

const AccountEditHeader = ({state}) => {
  const { guid } = useParams();
  const provenance = document.querySelector("[data-provenance]").dataset.provenance;
  let headTitle = "Address";
  switch (state) {
    case "my-details":
      headTitle = "Address"
      break;
    case "neighbour":
      headTitle = "Neighbour";
      break;
    case "safePlace":
      headTitle = "Safe place";
      break;
  
    default:
      break;
  }
  return(
    <div className="section-content yodel-container -narrow -negative-margin -head-section-content -with-header-actions -desktop-full-width">
      <a href={`${provenance == "review" ? `/my-details/${guid}/review` : provenance == "preferences" ? `/my-account/delivery/${guid}` : "/my-account/delivery-addresses"}`} className="yodel-container__header-action-container">
        <img className="yodel-header-back-arrow" src="/assets/yodel-assets/header-back-arrow.svg" alt="back-icon" />
      </a>
      <h2 className="yodel-container__header-title">{headTitle}</h2>
      <div className="yodel-container__header-action-container"></div>
    </div>
  );
}

const AccountSetupLayout = ({children, state}) => {
  const [isMobile, setIsMobile] = useState(isMobileWindow());

  const editMode = document.querySelector("[data-edit-mode]").dataset.editMode;

  const provenance = document.querySelector("[data-provenance]").dataset.provenance;

  const handleResize = () => {
    setIsMobile(isMobileWindow());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return(
    <section className={`${isMobile ? "-mobile" : "hidden-on-mobile"} account-setup-layout-section`}>
      {editMode && (provenance !== "additional_delivery_address") ?
        <AccountEditHeader state={state} />
      :
        <SetupStepIndicator state={state} />
      }
      <div className="section-content yodel-container -narrow account-setup-content">
        {React.cloneElement(children, { isMobile: isMobile })}
      </div>
    </section>
  )
};

export default AccountSetupLayout;
