import React, {useState, useEffect} from 'react';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import TextField from './text_field';
import ErrorBanner from '../my_account/error_banner';
import {sessionTimeoutLogout} from './session_timeout_logout_helper';

const validate = (values) => {
  const errors = {};

  const emailRegex =
    /^(?!.*\.@|.*\.{2})[a-zA-Z0-9-]+[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]*@[a-zA-Z0-9-]+\.+([a-zA-Z0-9-]{2,})+(\.+([a-zA-Z0-9-]{2,})+)*$/;

  if (!values.email) {
    errors.email = 'Please enter an email address';
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Please enter a valid email address';
  }

  return errors;
}

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      userId
      errors
    }
  }
`;

const ResetPasswordSection = () => {
  const [error, setError] = useState(null);
  const [sendPasswordResetEmail, { data }] =
    useMutation(SEND_PASSWORD_RESET_EMAIL);

  const handleSubmit = (values, actions) => {
    sendPasswordResetEmail(
      {variables: {email: values.email}}
    )
    .then(data => {
      const response = data.data && data.data.sendPasswordResetEmail;
      if (!response.errors.length) {
        window.location.href = '/reset_password/yours';
      } else if (response.errors[0] === 'Sorry, your account is not active!'){
        window.location.href = `/users/${response.userId}/activation/new`
      } else {
        actions.setSubmitting(false);
        setError(response.errors[0]);
        scrollToErrorBanner();
      }
    })
    .catch(error => {
      actions.setSubmitting(false);
      sessionTimeoutLogout(error.message);
      setError(error.message);
      scrollToErrorBanner();
    });
  };

  const scrollToErrorBanner = () => {
    document.getElementsByClassName("yodel-registration-error-banner")[0]
      .scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <>
      <p className="blue-paragraph">
        Forgot your password or don’t have one yet?
      </p>

      {error && <ErrorBanner error={error}/>}
      <div className="bordered-container">
        <p>
          Enter the email address for your account and we’ll send you a link to create a new password.
        </p>
      </div>

      <div className="expiry-line -forgotten-password">
        <img src="/assets/yodel-assets/timer-icon.svg" alt="timer-icon" />
        <p>The link in the email will expire after 30 minutes.</p>
      </div>

      <Formik
        initialValues={{
          email: '',
        }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, errors, isValid }) => (
          <Form
            className="user-sign-in-form"
            noValidate
          >
            <TextField
              label="Email address"
              name="email"
              type="email"
              showValidMarker={false}
            />

            <input
              type="submit"
              name="submit"
              value="Send"
              className="yodel-outline-button register-signup-btn"
              disabled={isSubmitting || !values?.email}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}

const ResetPasswordPage  = ({isMobile}) => {
  return (
    <div className={(isMobile ? "" : "-with-border ") + "signup-content"}>
      <ResetPasswordSection />
    </div>
  );
};

export default ResetPasswordPage;
