import React, {useState, useEffect} from 'react';

const isMobileWindow = () => {
  return window.innerWidth < 960;
}

const SimpleLayout = ({children}) => {
  const [isMobile, setIsMobile] = useState(isMobileWindow());

  const handleResize = () => {
    setIsMobile(isMobileWindow());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return(
    <section className={`${isMobile ? "-mobile" : "hidden-on-mobile"} yodel-registration-section`}>
      {React.cloneElement(children)}
    </section>
  )
};

export default SimpleLayout;
